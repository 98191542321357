import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../components/Button';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';

export default function PauseMonthConfirmed() {
  const { device } = usePurchaseSubscriptionContext();
  const petName = device.pet!.name;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h2>Billing has been paused.</h2>
            </div>
            <p>Your access to the Fi app, GPS tracking, and {petName}'s data will continue without interruption.</p>
            <p>
              This is a one-time courtesy. We will skip your next monthly payment and billing will resume thereafter.
            </p>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.claimPauseMonthDone({ petId: device.pet!.id });
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
