import React, { useRef, useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../AppPaths';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { Carousel, CarouselController } from '../../../../components/Carousel';
import { AppState, gqlTypes } from '../../../../types';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import classNames from 'classnames';
import lunaImg from '../../../../assets/images/in_app_cancellation/luna.png';
import lunaOwnerImg from '../../../../assets/images/in_app_cancellation/luna_owner.png';
import lunaStepCountImg from '../../../../assets/images/in_app_cancellation/luna_step_count.png';
import lunaXrayImg from '../../../../assets/images/in_app_cancellation/luna_xray.png';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { BaseInAppCancellationState, SubscriptionCategory } from '../util/types';
import { useSelector } from 'react-redux';

export default function DogDoesNotRunAway() {
  const history = useHistory();
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const [selectedPageIdx, setSelectedPageIdx] = useState(0);
  const [showNextButton, setShowNextButton] = useState(true);
  const carouselControllerRef = useRef<CarouselController>();
  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.dogDoesNotRunAwayCancel,
    selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY,
    device,
    subscriptionCategory,
  });
  const showMonthToMonthSpecialOffers = useSelector(
    (state: AppState) => state.config.siteConfig.showMonthToMonthSpecialOffers ?? false,
  );

  const showUpgradeVersions: string[] = ['S1', 'S2'];
  const deviceModel = device.currentCollarModel ?? '';

  if (!device.subscription?.id) {
    throw new Error('No recurly subscription found.');
  }

  if (loading) {
    return loading;
  }

  const offerFreeMonth = !!device.shouldOfferFreeMonthDuringCancellation;
  const offerPauseMonth = !!device.shouldOfferPauseMonthDuringCancellation;
  const offerS3Upgrade = showUpgradeVersions.includes(deviceModel);

  const handleKeep = () => {
    events.dogDoesNotRunAwayKeepMembership({ petId: device.pet?.id, subscriptionCategory });
    history.push({
      pathname: AppPaths.Subscription.KeepMembershipConfirmation(device.moduleId),
      state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY },
      search: window.location.search,
    });
  };

  const handleRetentionOffers = () => {
    events.dogDoesNotRunAwayContinue({ petId: device.pet?.id, subscriptionCategory });
    if (subscriptionCategory === SubscriptionCategory.MONTHLY && showMonthToMonthSpecialOffers) {
      // Take S3 monthly users to special offers screen.
      history.push({
        pathname: AppPaths.Subscription.SpecialOffers(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY, subscriptionCategory },
        search: window.location.search,
      });
    } else if (offerS3Upgrade) {
      // Take S1 and S2 users to upgrade flow.
      history.push({
        pathname: AppPaths.Subscription.Upgrade(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY, subscriptionCategory },
        search: window.location.search,
      });
    } else if (offerPauseMonth) {
      history.push({
        pathname: AppPaths.Subscription.PauseMonthOffer(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY, subscriptionCategory },
        search: window.location.search,
      });
    } else if (offerFreeMonth) {
      history.push({
        pathname: AppPaths.Subscription.FreeMonthOffer(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY, subscriptionCategory },
        search: window.location.search,
      });
    } else {
      throw new Error('Something went wrong.');
    }
  };

  const handleCancel = () => {
    events.dogDoesNotRunAwayContinue({ petId: device.pet?.id, subscriptionCategory });
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY,
          additionalText: undefined,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() =>
            events.dogDoesNotRunAwayBack({ petId: device.pet?.id, subscriptionCategory }, selectedPageIdx + 1)
          }
          closeButtonAnalyticsEvent={() =>
            events.dogDoesNotRunAwayClose({ petId: device.pet?.id, subscriptionCategory }, selectedPageIdx + 1)
          }
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.imagesCarouselContainer}>
            <Carousel
              cellAlign="center"
              className={styles.imageCarousel}
              pageDots={true}
              controllerRef={carouselControllerRef}
              prevNextButtons={true}
              onChange={(idx) => {
                idx === 2 ? setShowNextButton(false) : setShowNextButton(true);
                const analyticsPageIdx = selectedPageIdx + 1;
                if (idx > selectedPageIdx) {
                  events.dogDoesNotRunAwayNext({ petId: device.pet?.id, subscriptionCategory }, analyticsPageIdx);
                } else if (idx < selectedPageIdx) {
                  events.dogDoesNotRunAwayBack({ petId: device.pet?.id, subscriptionCategory }, analyticsPageIdx);
                }
                setSelectedPageIdx(idx);
              }}
            >
              <div className={styles.imageCarouselImage}>
                <div className={styles.largeHeader}>
                  <h1>Fi reveals more than just location</h1>
                </div>

                <img
                  className={styles.stepCount}
                  src={lunaStepCountImg}
                  alt="A bar graph showing declining step count day over day"
                />
                <p>
                  Data can often say what your dog can't. When super-active Frenchie Luna had taken a 20,000-step plunge
                  in daily activity, her owner Alex could see something was up.
                </p>
              </div>
              <div className={classNames(styles.imageCarouselImage, styles.xray)}>
                <div className={styles.largeHeader}>
                  <h1>Decoding Luna's (lack of) activity</h1>
                </div>
                <img src={lunaXrayImg} alt="Dog xray showing a swallowed rock" />
                <p>
                  Turns out, she had swallowed a rock and developed an abdominal obstruction. An X-ray confirmed what
                  the data was suggesting.
                </p>
              </div>
              <div className={styles.imageCarouselImage}>
                <div className={styles.largeHeader}>
                  <h1>Better health outcomes with Fi</h1>
                </div>
                <img src={lunaImg} alt="A dog walking towards camera wearing a Fi collar" />
                <div className={styles.testimonial}>
                  <img src={lunaOwnerImg} alt="Owner profile pic" className={styles.lunaOwner} />
                  <p>
                    “Because we got her help early, none of Luna's intestines became necrotic and no dangerous resection
                    was required. After emergency, life-saving surgery, Luna has made a full recovery!”
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {showNextButton ? (
            <>
              <Button
                className={styles.button}
                onClick={() => {
                  const temp: any = document.querySelector('.flickity-button.flickity-prev-next-button.next');
                  temp.click();
                }}
              >
                Next
              </Button>
              {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
              <div className={styles.buttonSpacer}></div>
            </>
          ) : (
            <>
              <Button className={styles.button} onClick={handleKeep}>
                Keep Membership
              </Button>

              {offerS3Upgrade || offerFreeMonth || offerPauseMonth ? (
                <Button className={styles.button} tertiary={true} onClick={handleRetentionOffers}>
                  Continue with Cancellation
                </Button>
              ) : (
                <CancelConfirmationModal
                  onContinue={() => handleCancel()}
                  pageName={'Dog Does Not Run Away'}
                  petId={device.pet!.id}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
