import React from 'react';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import { useHistory } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { troubleshoot as events } from '../../../lib/analytics/events';
import { mkError } from '../../../lib/util';
import AppPaths from '../../../AppPaths';
import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import { Image } from '../../../components/Image';

import CollarImage from '../../../assets/images/in_app_cancellation/s3_yellow_flat.png';
import ValuePropsBox from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import { WHATS_NEW_VALUE_PROPS } from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/WhatsNewValueProps';
import Button from '../../../components/Button';

export default function UpgradeSeries2() {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const petName = device.pet?.name ?? 'your dog';

  events.pageLoad({ petId: device.pet?.id }, 'Upgrade Series 2');

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleUpgrade = () => {
    events.series2UpgradeClicked({ petId: device.pet?.id });
    history.push({
      pathname: AppPaths.Series3Upgrade.SplashPageForPet(device.pet?.id),
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.series2UpgradeBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.series2UpgradeClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Upgrade your Series 2 collar: Get credit for a Series 3 </h1>
          <p>
            Our Fi series 3 collar is our smartest, smallest and most resilient collar yet. It could be just what{' '}
            {petName} needs.
          </p>
        </div>

        <Image
          className={styles.collarAvatar}
          image={{ type: 'image', url: CollarImage }}
          alt="The Fi Series 3 collar with a yellow band"
        />
        <div className={styles.whatsNew}>
          <h2>What's new?</h2>
          <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleUpgrade}>
            Upgrade Now
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
