import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../AppPaths';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import { mkError } from '../../../../lib/util';
import ValuePropsBox from '../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/ValuePropsBox';
import { WHATS_NEW_VALUE_PROPS } from '../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/WhatsNewValueProps';
import Button from '../../../../components/Button/Button';
import * as gqlTypes from '../../../../types/gql-op-types';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import yellowS3CollarImg from '../../../../assets/images/in_app_cancellation/s3_yellow_flat.png';
import { BaseInAppCancellationState } from '../util/types';

export interface UpgradeSeries2State extends BaseInAppCancellationState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function UpgradeSeries2() {
  const history = useHistory();
  const location = useLocation<UpgradeSeries2State>();
  const { selectedCancelReason, subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const petName = device.pet?.name ?? 'your dog';
  const petId = device.pet!.id;
  events.pageLoad(
    { petId, cancellationReason: selectedCancelReason, subscriptionCategory },
    'Upgrade Your Series 2 Collar',
  );

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleCancel = () => {
    if (!device.subscription?.id) {
      throw new Error('No recurly subscription found.');
    }

    events.upgradeS2Cancel({ petId, cancellationReason: selectedCancelReason, subscriptionCategory });

    history.push({
      pathname: AppPaths.Subscription.FreeMonthOffer(device.moduleId),
      state: { selectedCancelReason, subscriptionCategory },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.upgradeS2Back({ petId: device.pet?.id, subscriptionCategory })}
          closeButtonAnalyticsEvent={() => events.upgradeS2Close({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Upgrade your Series 2 collar: Get credit for a Series 3</h1>
          <p>
            Our Fi series 3 collar is our smartest, smallest and most resilient collar yet. It could be just what{' '}
            {petName} needs.
          </p>
        </div>

        <img className={styles.collarAvatar} src={yellowS3CollarImg} alt="Yellow collar flat" />
        <div className={styles.whatsNew}>
          <h2>What's new?</h2>
          <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.series2UpgradePageUpgradeClicked({ petId: petId, subscriptionCategory });
              history.push(AppPaths.Series3Upgrade.SplashPageForPet(petId));
            }}
          >
            Upgrade Now
          </Button>
          <Button onClick={handleCancel} className={styles.button} tertiary={true}>
            No, Cancel
          </Button>
        </div>
      </div>
    </>
  );
}
