import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { billingAccountQuery, updateBillingInfoMutation } from '../../../graphql-operations';
import { gqlTypes } from '../../../types';
import ErrorMessage, { useError } from '../../../components/ErrorMessage';
import Loading from '../../../components/Loading';
import ErrorMessages, { getCustomerMessageFromApolloError, logInternalError } from '../../../lib/errors';
import BillingInfo, { UpdateBillingInfoFunction } from '../../Checkout/pages/Billing/BillingInfo';
import styles from './PurchaseSubscriptionPayment.module.scss';

interface PurchaseSubscriptionPaymentBodyProps {
  onSubmit?: () => void;
  onCancel?: () => void;
}

export default function PurchaseSubscriptionPaymentBody({ onSubmit, onCancel }: PurchaseSubscriptionPaymentBodyProps) {
  const { error, errorID, setError, clearError } = useError();
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const [mutation, { loading: mutationLoading }] = useMutation<
    gqlTypes.updateBillingInfo,
    gqlTypes.updateBillingInfoVariables
  >(updateBillingInfoMutation);

  const { data: accountData, loading: accountLoading } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    onError(err) {
      logInternalError(err);
      setError(ErrorMessages.DEFAULT);
    },
  });

  const [updatingBillingInfo, setUpdatingBillingInfo] = useState(false);

  const handleUpdateBillingInfo: UpdateBillingInfoFunction = useCallback(
    async ({ input }) => {
      if (updatingBillingInfo) {
        return;
      }

      setUpdatingBillingInfo(true);
      try {
        await mutation({
          variables: { input },
          refetchQueries: [{ query: billingAccountQuery }],
        });
        setTimeout(() => history.goBack(), 1000);
        onSubmit && onSubmit();
      } catch (err) {
        setError(getCustomerMessageFromApolloError(err) ?? ErrorMessages.DEFAULT);
      } finally {
        setUpdatingBillingInfo(false);
      }
    },
    [mutation, history, updatingBillingInfo, setUpdatingBillingInfo, setError, onSubmit],
  );

  const isLoading = accountLoading || mutationLoading || updatingBillingInfo;

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.billingInfoContainer}>
      {success && <div className={styles.success}>Payment updated successfully</div>}
      {accountData && (
        <BillingInfo
          billingInfo={accountData.currentUser.billingAccount?.billingInfo ?? null}
          setError={setError}
          clearError={clearError}
          setSuccess={setSuccess}
          submitting={mutationLoading}
          handleNoChanges={() => {}}
          updateBillingInfo={handleUpdateBillingInfo}
          shippingAddress={undefined}
          startInEditMode
          compactForm
          onCancelEditMode={() => {
            onCancel && onCancel();
            // if there is history, go back; otherwise, this is a fresh webview, so close it
            history.length > 1 ? history.goBack() : window.open('fi://closeappview');
          }}
        />
      )}
      {error && <ErrorMessage errors={[error]} errorID={errorID} />}
    </div>
  );
}
