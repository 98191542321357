import React from 'react';
import { ReactComponent as IconMessage } from '../../../../../assets/images/icons/icon_message.svg';
import { ReactComponent as IconPhone } from '../../../../../assets/images/icons/icon_phone.svg';
import { ReactComponent as IconWarranty } from '../../../../../assets/images/icons/icon_warranty.svg';
import styles from '../../../ProductDetails.module.scss';
import ValuePropsBox from '../ValuePropsBox';
import { showNanoVariant } from '../../../../../components/NanoBanner/NanoBanner';

export default function MembershipValueProps() {
  const nanoVariantEnabled = showNanoVariant();
  return (
    <div className={styles.valuePropsSection}>
      {!nanoVariantEnabled && (
        <ValuePropsBox
          header={<div className={styles.subSectionHeader}>Membership includes</div>}
          valueProps={[
            {
              icon: <IconWarranty />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Fi Series 3 Warranty</span> with unlimited replacements
                    on active memberships.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconMessage />,
              text: (
                <div className={styles.valueProp}>
                  <div className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>7 days a week customer service</span> with email and
                    live chat support.
                  </div>
                </div>
              ),
            },
            {
              icon: <IconPhone />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Premium Phone Support</span> with all pre-paid plans.
                  </span>
                </div>
              ),
            },
          ]}
        />
      )}
      <div className={styles.multipleDogsHeader}>Have multiple dogs?</div>
      <div className={styles.multipleDogsDescription}>Get 5% off all Fi prepaid memberships & no activation fee.</div>
    </div>
  );
}
