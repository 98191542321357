import { gql } from '@apollo/client';

export const previewSubscriptionQuery = gql`
  query ECOMMERCE_previewSubscription($input: PurchaseSubscriptionInput!) {
    previewSubscription(input: $input) {
      activationFeeInCents
      taxInCents
      totalInCents
      giftCardAmountInCents
      couponAmountInCents
    }
  }
`;

export const purchaseSubscriptionMutation = gql`
  mutation ECOMMERCE_purchaseSubscription($input: PurchaseSubscriptionInput!) {
    purchaseSubscription(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;

export const deviceSubscriptionQuery = gql`
  query ECOMMERCE_deviceSubscription($moduleId: String!) {
    device(moduleId: $moduleId) {
      subscription {
        currentTermEndsAt
      }
    }
  }
`;

export const purchaseSubscriptionDeviceQuery = gql`
  query ECOMMERCE_purchaseSubscriptionDevice(
    $moduleId: String!
    $inAppFlow: Boolean
    $monthlyUpgrade: Boolean
    $couponCode: String
  ) {
    device(moduleId: $moduleId) {
      ...purchaseSubscriptionDeviceDetails
    }
  }

  fragment purchaseSubscriptionDeviceDetails on Device {
    id
    moduleId
    currentCollarModel
    purchasableSubscriptionOptions(inAppFlow: $inAppFlow, monthlyUpgrade: $monthlyUpgrade, couponCode: $couponCode) {
      ...subscriptionOption
    }
    preselectedPlanCodeForNewSubscription
    pet {
      ...purchaseSubscriptionPetDetails
    }
    subscription {
      id
      billingEmail
      currentTermEndsAt
      expiresAt
      subscriptionOption {
        name
        priceInCents
        billingCadence
        displayedRate
        displayedRateUnit
        renewalYears
        renewalMonths
        billingPeriodsPerTerm
        sku
        buyItMembership
      }
    }
    subscriptionAccessType
    hasSubscriptionOverride
    shouldOfferFreeMonthDuringCancellation
    shouldOfferPauseMonthDuringCancellation
    shouldOfferDowngradeDuringCancellation
    subscriptionRenewalStatus {
      shouldOfferSubscriptionRenewal
    }
    upgradeRedeemed
  }

  fragment purchaseSubscriptionPetDetails on Pet {
    id
    name
    gender
    photos {
      first {
        image {
          fullSize
        }
      }
    }
    household {
      pets {
        id
        name
        device {
          subscriptionAccessType
        }
        photos {
          first {
            image {
              fullSize
            }
          }
        }
      }
    }
  }

  fragment subscriptionOption on SubscriptionOption {
    sku
    name
    priceInCents
    billingCadence
    displayedRate
    displayedRateUnit
    fullRate
    fullRateUnit
    savings
    monthlyUpgradeSavings
    featured
    renewalYears
    activationFeeInCents
    renewalMonths
    billingPeriodsPerTerm
    upsellOnly
    buyItMembership
    valueLabel
    supportPlus
    couponExtensionMonths
    planSwitchCreditAppliedInCents
    planSwitchAmountChargedInCents
  }
`;
