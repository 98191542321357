import { PayloadAction } from '@reduxjs/toolkit';
import { COUPON_COOKIE_NAME, removeCookie } from '../lib/util';
import { REFERRAL_COOKIE_NAME } from '../lib/util/referralCookie';
import * as types from '../types';

interface IRemoveCartItem {
  id: string;
}

interface IRemoveCartItems {
  ids: string[];
}

/**
 * Reducers that could apply to all carts (e.g. manipulating cart items)
 */
export const baseCartReducers = {
  removeCartItem<T extends types.Cart>(cart: T, { payload }: PayloadAction<IRemoveCartItem>) {
    const { [payload.id]: _, ...remainingCartItems } = cart.cartItems;

    return {
      ...cart,
      cartItems: remainingCartItems,
    };
  },
  removeCartItems<T extends types.Cart>(cart: T, { payload }: PayloadAction<IRemoveCartItems>) {
    let cartItems = { ...cart.cartItems };
    for (const id of payload.ids) {
      const { [id]: _, ...remainingCartItems } = cartItems;
      cartItems = remainingCartItems;
    }

    return {
      ...cart,
      cartItems,
    };
  },
};

/**
 * Reducers that only apply to carts that can apply coupons
 *
 */
export const couponCartReducers = {
  addCoupon<T extends types.Cart>(cart: T, { payload: couponCode }: PayloadAction<string>) {
    // Coupon and referral code cannot be stacked
    const { referralCode, ...cartWithoutReferralCode } = cart;
    removeCookie(REFERRAL_COOKIE_NAME);

    return {
      ...cartWithoutReferralCode,
      couponCode,
    };
  },
  removeCoupon<T extends types.Cart>(cart: T) {
    removeCookie(COUPON_COOKIE_NAME);

    return {
      ...cart,
      couponCode: undefined,
    };
  },
};

interface ReferralDetails {
  code: string;
  referrerName?: string;
}

/**
 * Reducers that only apply to carts that can add referral codes
 *
 */
export const referralCodeCartReducers = {
  addReferral<T extends types.Cart>(cart: T, { payload: referralDetails }: PayloadAction<ReferralDetails>): types.Cart {
    // Coupon and referral code cannot be stacked
    const { couponCode, ...cartWithoutCouponCode } = cart;
    removeCookie(COUPON_COOKIE_NAME);

    return {
      ...cartWithoutCouponCode,
      referralCode: referralDetails.code,
      referrerName: referralDetails.referrerName,
    };
  },
  removeReferral<T extends types.Cart>(cart: T): types.Cart {
    removeCookie(REFERRAL_COOKIE_NAME);

    return {
      ...cart,
      referralCode: undefined,
      referrerName: undefined,
    };
  },
};

/**
 * Reducers that only apply to carts that can redeem a gift card
 *
 */
export const giftCardRedemptionCartReducers = {
  addGiftCard<T extends types.Cart>(cart: T, { payload: redeemedGiftCardCode }: PayloadAction<string>) {
    return {
      ...cart,
      redeemedGiftCardCode,
    };
  },
  removeGiftCard<T extends types.Cart>(cart: T) {
    return {
      ...cart,
      redeemedGiftCardCode: undefined,
    };
  },
};
