import { useCallback, useContext, useMemo } from 'react';
import CheckoutContext from '../lib/CheckoutContext';
import { getCustomerMessageFromApolloError, logInternalError } from '../lib/errors';
import * as types from '../types';
import { setGlobalVariablesForConversionTracking } from '../lib/analytics/analyticsUtils';

interface UseHandlePurchaseProps {
  cartPricing: types.CartPricing;
  onError: (errorMessage: string) => void;
  onSuccess: (
    cart: types.Cart,
    cartPricing: types.CartPricing,
    invoiceNumber: string,
    isReturningCustomer: boolean,
  ) => void;
}

export default function useHandlePurchase({ cartPricing, onError, onSuccess }: UseHandlePurchaseProps) {
  const { cart, checkoutState, purchaseMutationLoading, executePurchaseMutation } = useContext(CheckoutContext);

  // Exclude any invalid codes from the input
  const cartForPurchase: types.Cart = useMemo(
    () => ({
      ...cart,
      couponCode: cartPricing.validationErrors?.couponCode ? undefined : cart.couponCode,
      redeemedGiftCardCode: cartPricing.validationErrors?.redeemedGiftCardCode ? undefined : cart.redeemedGiftCardCode,
      referralCode: cartPricing.validationErrors?.referralCode ? undefined : cart.referralCode,
    }),
    [
      cart,
      cartPricing.validationErrors?.couponCode,
      cartPricing.validationErrors?.redeemedGiftCardCode,
      cartPricing.validationErrors?.referralCode,
    ],
  );

  const handlePurchase = useCallback(
    async (emailAddress: string | undefined, shippingCode?: types.ShippingCode) => {
      try {
        const result = await executePurchaseMutation(cartForPurchase, {
          ...checkoutState,
          shippingCode: shippingCode ?? checkoutState.shippingCode,
        });

        const { chargeInvoice, giftCardInvoices, isReturningCustomer } = result;
        const invoiceNumber = chargeInvoice?.number ?? giftCardInvoices?.[0]?.number;

        const cartItemsArray = Object.values(cartForPurchase.cartItems);
        setGlobalVariablesForConversionTracking(invoiceNumber, cartItemsArray, cartPricing, emailAddress);

        onSuccess(cartForPurchase, cartPricing, invoiceNumber, isReturningCustomer);
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        if (customerMessage) {
          onError(customerMessage);
        } else {
          logInternalError(err);
          onError('Failed to process purchase. Please try again or contact support@tryfi.com if the problem persists.');
        }
      }
    },
    [cartForPurchase, cartPricing, checkoutState, executePurchaseMutation, onError, onSuccess],
  );

  return {
    handlePurchase,
    purchaseMutationLoading,
  };
}
