import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import * as events from '../../../lib/analytics/events';
import { MARTINGALE_BAND_PRODUCT_ID } from '../../../lib/product';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import * as types from '../../../types';
import { Series } from '../../../types/Series';
import AddToBagButton from '../components/AddToBagButton';
import BandSeriesToggle from '../components/BandSeriesToggle';
import BandVariantOptions from '../components/BandVariantOptions';
import ProductTitle from '../components/ProductTitle';
import SoldOutBanner from '../components/SoldOutBanner';
import useIsSkuBlocked from '../hooks/useIsSkuBlocked';
import useVariantSelector from '../hooks/useVariantSelector';
import styles from '../ProductDetails.module.scss';

interface OnChangeHandlerProps {
  priceInCents: number;
  productName: string;
}

function useOnChangeHandlers({ priceInCents, productName }: OnChangeHandlerProps) {
  const onColorChange = useCallback(
    (newVariant: types.IVariant) => {
      events.martingaleBandColorSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSizeChange = useCallback(
    (newVariant: types.IVariant) => {
      events.martingaleBandSizeSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSelectedVariantChange = useCallback(
    (newVariant: types.IVariant) => {
      events.martingaleBandVariantSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  return {
    onColorChange,
    onSizeChange,
    onSelectedVariantChange,
  };
}

interface MartingaleBandDetailsProps {
  priceInCents: number;
  productName: string;
  variants: types.IVariant[];
}

export default function MartingaleBandDetails({ priceInCents, productName, variants }: MartingaleBandDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    priceInCents,
    productName,
  });

  const { changeVariantOptions, selectedVariant } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants,
  });
  const isBlocked = useIsSkuBlocked(selectedVariant.sku);

  useEffect(() => {
    events.martingaleBandViewed();
  }, []);

  return (
    <>
      <div className={styles.productContainer}>
        <div className={styles.imageGalleryContainer}>
          <ImageGallery
            media={[
              {
                type: 'image',
                url: `/product_images/martingale-band/gray.jpg`,
                retinaWidth: 612,
                retinaHeight: 440,
              },
            ]}
          />
        </div>
        <div className={styles.productDetails}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <p className={styles.description}>
              Specially designed for dogs with narrower heads (i.e. Greyhounds) and big pullers or flight-risk pups who
              can back out of regular collars. Martingales provide extra safety by tightening around your dog's neck
              (without choking) when they start to pull.
            </p>
          </div>

          {/* Martingale bands are only available for Series 2 so this is a static toggle acting more like a label */}
          <BandSeriesToggle
            seriesProductIdMap={new Map([[Series.Series2, MARTINGALE_BAND_PRODUCT_ID]])}
            selectedSeries={Series.Series2}
            variantOptions={selectedVariant.options}
          />

          <BandVariantOptions
            bandSeries={types.BandSeries.F1}
            changeVariantOptions={changeVariantOptions}
            selectedVariant={selectedVariant}
            variants={variants}
            startSizeAsUnselected={false}
          />

          {isBlocked && <SoldOutBanner />}

          <div className={styles.productAction}>
            <AddToBagButton
              disabled={isBlocked}
              onAddToBag={() => {
                if (isBlocked) {
                  return false;
                }

                events.martingaleBandAddedToBag({
                  color: selectedVariant.options.color,
                  priceInCents,
                  productName,
                  size: selectedVariant.options.size,
                  sku: selectedVariant.sku,
                });

                dispatch(
                  storeCartActions.addSingleLineCartItem({
                    sku: selectedVariant.sku,
                  }),
                );

                history.push(AppPaths.Bag);

                return true;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
