import * as types from '../types';
import {
  CouponError,
  GiftCardError,
  ReferralCodeError,
  throwCouponValidationError,
  throwGiftCardValidationError,
  throwReferralCodeValidationError,
} from './errors';
import { fetchCouponDetailsFromRecurly, priceCartForPreview } from './pricing';
import { webApiClient } from './fi-api/apiUtils';

const VALIDATE_PROMO_CODE_ENDPOINT = '/api/ecommerce/validatecode';
type PromoCodeType = 'coupon' | 'referral' | 'giftCard' | 'internalCoupon';

interface PromoCodeValidationResults {
  promoCodeType: PromoCodeType;
  referrerName?: string;
}

export interface PromoCodeValidationErrorResponse {
  code: string;
  message: string;
}

type CouponApplicationResult = types.Result<types.CouponDetails, CouponError>;
type GiftCardApplicationResult = types.Result<types.GiftCardDetails, GiftCardError>;
type ReferralCodeApplicationResult = types.Result<types.ReferralDetails, ReferralCodeError>;

/**
 * Attempt to apply a coupon to the cart.
 */
export async function applyCouponToCart(cart: types.Cart, couponCode: string): Promise<CouponApplicationResult> {
  try {
    const result = await priceCartForPreview(cart, { applyCoupon: couponCode });
    if (result.validationErrors?.couponCode) {
      throwCouponValidationError(result.validationErrors.couponCode);
    }

    const couponDetails = result.couponDiscount
      ? {
          code: couponCode,
          discount: result.couponDiscount,
        }
      : await fetchCouponDetailsFromRecurly(couponCode);
    if (!couponDetails) {
      throw CouponError.unknown(new Error(`Missing coupon details!`));
    }

    return {
      kind: 'success',
      value: couponDetails,
    };
  } catch (err) {
    if (err instanceof CouponError) {
      return { kind: 'failure', err };
    }

    return { kind: 'failure', err: CouponError.unknown(err) };
  }
}

/**
 * Attempt to apply a gift card to the cart.
 */
export async function applyGiftCardToCart(
  cart: types.Cart,
  redeemedGiftCardCode: string,
): Promise<GiftCardApplicationResult> {
  try {
    const result = await priceCartForPreview(cart, {
      applyGiftCard: redeemedGiftCardCode,
    });
    if (result.validationErrors?.redeemedGiftCardCode) {
      throwGiftCardValidationError(result.validationErrors.redeemedGiftCardCode);
    }

    const giftCardDetails = {
      redemptionCode: redeemedGiftCardCode,
      totalValueOfGiftCardInCents: result.totalValueOfRedeemedGiftCardInCents ?? 0,
    };

    return {
      kind: 'success',
      value: giftCardDetails,
    };
  } catch (err) {
    if (err instanceof GiftCardError) {
      return { kind: 'failure', err };
    }

    return { kind: 'failure', err: GiftCardError.unknown(err) };
  }
}

export async function applyReferralCodeToCart(
  cart: types.Cart,
  referralCode: string,
): Promise<ReferralCodeApplicationResult> {
  try {
    const result = await priceCartForPreview(cart, {
      applyReferralCode: referralCode,
    });
    if (result.validationErrors?.referralCode) {
      throwReferralCodeValidationError(result.validationErrors.referralCode);
    }
    return {
      kind: 'success',
      value: { referralCode, referrerName: result.referrerName },
    };
  } catch (err) {
    if (err instanceof ReferralCodeError) {
      return { kind: 'failure', err };
    }

    return { kind: 'failure', err: ReferralCodeError.unknown(err) };
  }
}

/**
 * Validates a code against all 3 types of code: coupon, referral, gift card
 */
export async function validatePromoCode(cart: types.Cart, promoCode: string): Promise<PromoCodeValidationResults> {
  const { data } = await webApiClient.post<PromoCodeValidationResults>(VALIDATE_PROMO_CODE_ENDPOINT, {
    cartItems: Object.values(cart.cartItems),
    // Codes already attached to the cart
    couponCode: cart.couponCode,
    redeemedGiftCardCode: cart.redeemedGiftCardCode,
    referralCode: cart.referralCode,
    // New code to validate
    validatePromoCode: promoCode,
  });

  return data;
}

/**
 * "Valid" in the context of this function means that the code exists. Other failures when trying to apply
 * a code to a cart (e.g. "Not applicable to any items in cart") are still considered valid for the purposes
 * of this function.
 */
export function isValidCodeResult(
  result: CouponApplicationResult | GiftCardApplicationResult | ReferralCodeApplicationResult,
): boolean {
  if (result.kind === 'failure' && ['not-found', 'unknown'].includes(result.err.type)) {
    return false;
  }

  return true;
}
