import { Elements } from '@recurly/react-recurly';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import AppPaths from '../../AppPaths';
import { ReactComponent as AppleLogoWhite } from '../../assets/images/apple_logo_white.svg';
import { browserSupportsApplePay } from '../../hooks/useShouldShowApplePay';
import { FiApplePayError } from '../../lib/RecurlyProvider';
import { APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS, UserFacingError, logInternalError } from '../../lib/errors';
import { getBrowser } from '../../lib/util';
import Button from '../Button';
import FiRecurlyProvider from '../FiRecurlyProvider';
import { SimpleDismissableModal } from '../modals';
import styles from './ApplePayCheckout.module.scss';
import ApplePayPurchaseResults from './ApplePayPurchaseResults';
import { IApplePayEvents } from './Events';
import FinishedRedirect from './FinishedRedirect';
import useApplePay from './useApplePay';

interface ApplePayOrNonSafariButtonProps {
  /**
   * If true, display an error alert when the apple pay account exists,
   * instead of redirecting the user to login.
   */
  errorAlertOnAccountExists?: boolean;
  events: IApplePayEvents;
  onError(error: Error): void;
  onSuccess?(results: ApplePayPurchaseResults): void;
  /**
   * If true, display a "mock" Apple Pay button that, when clicked, tells the user to use Safari if they are not using
   * an Apple Pay supported browser.
   */
  showNonSafariApplePayButton?: boolean;
}

interface ApplePayCheckoutProps extends ApplePayOrNonSafariButtonProps {
  onApplePayInitializationError: (error: Error) => void;
  onSuccess(results: ApplePayPurchaseResults): void;
}

interface ApplePayCheckoutState {
  accountExistsRedirect: boolean;
  loading: boolean;
}

function ApplePayCheckout({ errorAlertOnAccountExists, events, onError, onSuccess }: ApplePayCheckoutProps) {
  const [state, setState] = useState<ApplePayCheckoutState>({
    accountExistsRedirect: false,
    loading: false,
  });

  const [applePayReady, setApplePayReady] = useState(false);

  /**
   * Propagates the error to the parent component plus re-enables the Apple Pay button
   */
  const handleError = useCallback(
    (error: Error) => {
      if (applePayReady) {
        onError(error);
      } else {
        logInternalError(error);
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    },
    [applePayReady, onError],
  );

  const onCustomError = useCallback(
    (error: FiApplePayError) => {
      if (error.code === APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS && !errorAlertOnAccountExists) {
        setState((prevState) => ({ ...prevState, accountExistsRedirect: true }));
      } else {
        handleError(new UserFacingError(error.message));
      }
    },
    [errorAlertOnAccountExists, handleError],
  );

  const onCancel = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: false }));
  }, [setState]);

  const onReady = useCallback(() => {
    setApplePayReady(true);
  }, [setApplePayReady]);

  const disabled = useMemo(() => state.loading, [state.loading]);

  const applePay = useApplePay({
    events,
    onCancel,
    onCustomError,
    onError: handleError,
    onReady,
    onSuccess,
  });

  const onSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    if (applePayReady) {
      setState((prevState) => ({ ...prevState, loading: true }));

      events.applePayBegin();
      applePay.begin();
    } else {
      onError(
        new UserFacingError(
          'We were unable to initialize Apple Pay. Please reload the page and try again, or contact support@tryfi.com if the issue persists.',
        ),
      );
    }
  }, [applePay, applePayReady, disabled, events, onError]);

  if (state.accountExistsRedirect) {
    return (
      <Redirect
        to={{
          pathname: AppPaths.Login,
          search: `?returnTo=${AppPaths.Bag}`,
          state: { applePayError: true },
        }}
      />
    );
  }

  return (
    <>
      <Button
        className={classNames(styles.applePayButton, {
          [styles.loading]: state.loading,
        })}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();

          if (!disabled) {
            onSubmit();
          }
        }}
      >
        <div className={styles.content}>
          <AppleLogoWhite />
          <span>Pay</span>
        </div>
      </Button>
    </>
  );
}

const NonSafariApplePayButton = ({ events }: Pick<ApplePayCheckoutProps, 'events'>) => {
  const button = (
    <Button className={styles.applePayButton}>
      <AppleLogoWhite />
      <span>Pay</span>
    </Button>
  );

  return (
    <SimpleDismissableModal
      trigger={button}
      onOpen={() => {
        events.nonSafariApplePayClick();
      }}
      onClose={() => {
        events.nonSafariApplePayClose();
      }}
    >
      <p>Please open with Safari to use Apple Pay.</p>
    </SimpleDismissableModal>
  );
};

export default function ApplePayOrNonSafariButton({
  errorAlertOnAccountExists,
  events,
  onError,
  onSuccess,
  showNonSafariApplePayButton = true,
}: ApplePayOrNonSafariButtonProps) {
  const [purchaseResults, setPurchaseResults] = useState<ApplePayPurchaseResults | undefined>();

  const browser = getBrowser(window);
  const [applePayInitializationError, setApplePayInitializationError] = useState<Error | null>(null);

  // We only get here if the OS supports Apple Pay, so now determine
  // if the browser supports it and either:
  // 1. Show the Apple Pay button if it's supported
  // 2. Show a faux button as long as the browser isn't Safari (i.e. unsupported on this device)
  const browserSupported = browserSupportsApplePay();
  if (browserSupported) {
    // Just hide the button if we were unable to initialze the Apple Pay button
    if (applePayInitializationError) {
      return null;
    }

    return (
      <FiRecurlyProvider nonEssential>
        <Elements>
          <ApplePayCheckout
            errorAlertOnAccountExists={errorAlertOnAccountExists}
            events={events}
            onApplePayInitializationError={(err) => setApplePayInitializationError(err)}
            onError={onError}
            onSuccess={onSuccess ?? setPurchaseResults}
          />
          {purchaseResults && (
            <FinishedRedirect
              cart={purchaseResults.orderedCart}
              cartPricing={purchaseResults.orderedCartPricing}
              orderId={purchaseResults.invoiceNumber}
              isReturningCustomer={purchaseResults.isReturningCustomer}
            />
          )}
        </Elements>
      </FiRecurlyProvider>
    );
  } else if (showNonSafariApplePayButton && browser.name && !/Safari/i.test(browser.name)) {
    return <NonSafariApplePayButton events={events} />;
  } else {
    return null;
  }
}
