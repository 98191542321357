import React, { MutableRefObject, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AppPaths from '../../../../AppPaths';
import * as types from '../../../../types';
import SizeOptions from '../SizeOptions';
import ColorOptions from '../ColorOptions';
import styles from './BandVariantOptions.module.scss';
import { BandMaker } from '../../../../types';
import * as events from '../../../../lib/analytics/events';

const DEFAULT_SIZE_TITLE = 'Select your size';

export enum BandVariantGroup {
  FiOriginals = 'FiOriginals',
  MakersEdition = 'MakersEdition',
}

interface BandVariantOptionsProps {
  bandSeries: types.BandSeries;
  changeVariantOptions: (newOptions: Partial<types.IVariantOptions>) => void;
  selectedVariant: types.IVariant;
  variants: types.IVariant[];
  sizePickerRef?: MutableRefObject<HTMLDivElement | null>;
  startSizeAsUnselected: boolean;
  hideMakersLink?: boolean;
  showColorTitle?: boolean;
  selectedColorLabelPosition?: 'top' | 'bottom';
  sizeTitle?: string;
}

function getFilteredVariants(selectedBandVariantGroup: BandVariantGroup, variants: types.IVariant[]): types.IVariant[] {
  return selectedBandVariantGroup === BandVariantGroup.FiOriginals
    ? variants.filter((variant) => variant.bandMaker === BandMaker.Fi)
    : variants.filter((variant) => variant.bandMaker !== BandMaker.Fi);
}

export default function BandVariantOptions({
  bandSeries,
  changeVariantOptions,
  selectedVariant,
  variants,
  sizePickerRef,
  startSizeAsUnselected,
  hideMakersLink,
  showColorTitle,
  selectedColorLabelPosition,
  sizeTitle = DEFAULT_SIZE_TITLE,
}: BandVariantOptionsProps) {
  const [selectedBandVariantGroup, setSelectedBandVariantGroup] = useState(
    selectedVariant.bandMaker === BandMaker.Fi ? BandVariantGroup.FiOriginals : BandVariantGroup.MakersEdition,
  );
  const hasNonFiBandMaker = variants.some((variant) => variant.bandMaker !== BandMaker.Fi);
  const hasAnyUpchargedBands = useMemo(() => variants.some((variant) => !!variant.additionalPriceInCents), [variants]);
  const onVariantGroupSelect = function (bandVariantGroup: BandVariantGroup) {
    setSelectedBandVariantGroup(bandVariantGroup);
    const newFilteredVariants = getFilteredVariants(bandVariantGroup, variants);
    changeVariantOptions({ color: newFilteredVariants[0]?.options.color, size: newFilteredVariants[0]?.options.size });
    events.series3CollarEditionSwitch(bandVariantGroup);
  };

  return (
    <>
      <div className={styles.variantOptions}>
        <ColorOptions
          hasNonFiBandMaker={hasNonFiBandMaker}
          hasAnyUpchargedBands={hasAnyUpchargedBands}
          selectedColorOption={selectedVariant.options.color}
          selectedVariant={selectedVariant}
          selectedBandVariantGroup={selectedBandVariantGroup}
          onVariantGroupSelect={onVariantGroupSelect}
          onSelect={(newColorOption: types.ColorOption) => {
            changeVariantOptions({ color: newColorOption });
          }}
          variants={getFilteredVariants(selectedBandVariantGroup, variants)}
          showTitle={showColorTitle}
          selectedColorLabelPosition={selectedColorLabelPosition}
        />

        {!hasNonFiBandMaker && !hideMakersLink && (
          <Link className={styles.fiMakersLink} to={AppPaths.FiMakers} onClick={() => events.series3CollarFiMakers()}>
            More options with Fi Makers
          </Link>
        )}
      </div>

      <div ref={sizePickerRef}>
        <SizeOptions
          bandSeries={bandSeries}
          selectedVariant={selectedVariant}
          onOptionChange={changeVariantOptions}
          title={sizeTitle}
          variants={getFilteredVariants(selectedBandVariantGroup, variants)}
          startAsUnselected={startSizeAsUnselected}
        />
      </div>
    </>
  );
}
