import { zendeskCancellationReason } from '../types/gql-op-types';

interface IGTagDataLayer {
  push(...args: any[]): void;
}

interface adaResetArgs {
  greeting: string;
  metaFields: { customername: string; email: string; 'SubCancel-Reason': zendeskCancellationReason };
}

export interface AnalyticsMessage {
  event: string;
  properties: Object;
  options?: SegmentAnalytics.SegmentOpts;
}

export interface FiAnalyticsJS extends SegmentAnalytics.AnalyticsJS {
  off?: (event: string, fn: Function) => void;
}

export interface IWindow extends Window {
  recurly: any;
  fbq?: any;

  analytics?: FiAnalyticsJS;

  dataLayer?: IGTagDataLayer;

  letterpress?(...args: any[]): void;

  ApplePaySession?: typeof ApplePaySession;

  hj?: typeof HotJar.hj;

  // ada.cx is our CX chat provider, embedded via Google Tag Manager (https://tagmanager.google.com/#/container/accounts/4701326477/containers/11191378/workspaces/139/tags/32)
  adaEmbed?: {
    toggle(): void;
    reset(args: adaResetArgs): () => void;
  };
}

export const typedWindow: IWindow = window;
