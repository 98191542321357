import Cookies from 'js-cookie';
import React, { useCallback, useMemo, useState } from 'react';
import ErrorMessage from '../../../../components/ErrorMessage';
import { logInternalError, PromoCodeError } from '../../../../lib/errors';
import { isAxiosError } from '../../../../lib/fi-api/apiUtils';
import {
  applyCouponToCart,
  applyGiftCardToCart,
  PromoCodeValidationErrorResponse,
  validatePromoCode,
} from '../../../../lib/promoCode';
import { COUPON_COOKIE_NAME, generateID } from '../../../../lib/util';
import * as types from '../../../../types';
import { SubscriptionOption } from '../../../../components/SubscriptionOptionDetails';
import styles from './PromoCodeEntry.module.scss';

interface PromoCodeEntryProps {
  moduleId: string;
  onGiftCardCodeChange(code: string | null): void;
  onCouponCodeChange(code: string | null): void;
  subscriptionProduct: SubscriptionOption;
}

export default function PromoCodeEntry({
  moduleId,
  onGiftCardCodeChange,
  onCouponCodeChange,
  subscriptionProduct,
}: PromoCodeEntryProps) {
  const [error, setError] = useState<string | null>(null);

  // The PurchaseSubscription flow does not use the cart model, but we still want to use the new promo code
  // validation functionality which does require a cart. So we mock up a cart object here with the subscription
  // we want to buy.
  const cartItemId = generateID();
  const mockCart: types.SubscriptionCart = useMemo(
    () => ({
      cartItems: {
        [cartItemId]: {
          forModuleId: moduleId,
          cartItemId,
          lineItem: {
            lineItemId: generateID(),
            sku: subscriptionProduct.sku,
          },
          quantity: 1,
        },
      },
    }),
    [moduleId, subscriptionProduct, cartItemId],
  );

  const handleApplyCode = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const code = evt.currentTarget.value.trim();

      // Validate the code on the BE
      validatePromoCode(mockCart, code)
        .then((codeValidationResult) => {
          if (codeValidationResult.promoCodeType === 'coupon') {
            return applyCouponToCart(mockCart, code).then((couponCodeResult) => {
              if (couponCodeResult.kind === 'success') {
                // Only gift card or coupons can be applied at a time
                onGiftCardCodeChange(null);
                onCouponCodeChange(code);
                setError(null);
              } else {
                Cookies.remove(COUPON_COOKIE_NAME);
                setError(couponCodeResult.err.message);
              }
            });
          }

          if (codeValidationResult.promoCodeType === 'giftCard') {
            return applyGiftCardToCart(mockCart, code).then((giftCardResult) => {
              if (giftCardResult.kind === 'success') {
                // Only gift card or coupons can be applied at a time
                onCouponCodeChange(null);
                onGiftCardCodeChange(code);
                setError(null);
              } else {
                setError(giftCardResult.err.message);
              }
            });
          }
        })
        .catch((err) => {
          if (isAxiosError(err) && err.response?.data.error) {
            const errorResponseData: PromoCodeValidationErrorResponse = err.response?.data.error;
            setError(errorResponseData.message);
          } else if (err instanceof PromoCodeError) {
            setError(err.message);
          } else {
            setError('An unknown error occurred. Please try again.');
            logInternalError(err);
          }

          Cookies.remove(COUPON_COOKIE_NAME);
        });
    },
    [setError, mockCart, onCouponCodeChange, onGiftCardCodeChange],
  );

  return (
    <div className={styles.main}>
      <input
        name="code"
        type="text"
        autoCorrect={'off'}
        autoCapitalize={'off'}
        autoComplete={'off'}
        onChange={handleApplyCode}
      />
      {error && <ErrorMessage errors={[error ?? '']} />}
    </div>
  );
}
