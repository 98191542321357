import React, { useState } from 'react';
import Chooser from '../../../../components/Chooser';
import USStateDropdown from '../../../../components/USStateDropdown';
import * as types from '../../../../types';
import styles from './Billing.module.scss';
import { useCartMode } from '../../../../lib/cartModes';

function StateSelectorWithRecurlyInput() {
  const [state, setState] = useState('');
  return (
    <>
      <USStateDropdown state={state} onChange={(newState) => setState(newState)} />
      <input type="hidden" data-recurly="state" value={state} />
    </>
  );
}

function ExistingAddressForm({ shippingAddress }: { shippingAddress: types.Address }) {
  return (
    <div key="shipping-address">
      <input type="hidden" data-recurly="address1" defaultValue={shippingAddress.line1} />
      <input type="hidden" data-recurly="address2" defaultValue={shippingAddress.line2 || ''} />
      <input type="hidden" data-recurly="city" defaultValue={shippingAddress.city} />
      <input type="hidden" data-recurly="state" defaultValue={shippingAddress.state} />
      <input type="hidden" data-recurly="postal_code" defaultValue={shippingAddress.zip} />
      <input type="hidden" value="US" data-recurly="country" />
    </div>
  );
}

interface NewAddressFormProps {
  placeholderAddress?: types.Address;
}
function NewAddressForm({ placeholderAddress }: NewAddressFormProps) {
  return (
    <div key="new-billing-address" className={styles.newBillingAddressForm}>
      <input
        type="text"
        name="address1"
        data-recurly="address1"
        placeholder={placeholderAddress?.line1 || 'Address'}
        autoComplete="street-address"
        data-hj-whitelist
      />
      <input
        type="text"
        name="address2"
        data-recurly="address2"
        placeholder={placeholderAddress?.line2 || 'Apt/suite/other (optional)'}
        autoComplete="address-line2"
        data-hj-whitelist
      />
      <input
        type="text"
        data-recurly="city"
        name="city"
        placeholder={placeholderAddress?.city || 'City'}
        autoComplete="address-level2"
        data-hj-whitelist
      />
      <div className={styles.formRow}>
        <StateSelectorWithRecurlyInput />

        <input
          type="text"
          name="zip"
          data-recurly="postal_code"
          placeholder={placeholderAddress?.zip || 'Zip Code'}
          autoComplete="postal-code"
          className="zip-code"
          data-hj-whitelist
        />
      </div>
      <input type="hidden" value="US" data-recurly="country" />
    </div>
  );
}

interface AddressFormProps {
  shippingAddress: types.Address | undefined;
  useShippingAddress: boolean;
  billingInfo?: types.BillingInfo | null;
}

function AddressForm({ shippingAddress, billingInfo, useShippingAddress }: AddressFormProps) {
  const { checkoutContext } = useCartMode();

  if (useShippingAddress && shippingAddress) {
    return <ExistingAddressForm shippingAddress={shippingAddress} />;
  }

  // The ExistingAddressForm assumes a user has recently entered a shipping address,
  // but this is not the case in the context of managing one's subscription, so here
  // we surface a NewAddressForm, which displays a full address form prepopulated
  // with existing billing information.
  if (checkoutContext === types.PaymentContext.MANAGE_SUBSCRIPTION) {
    return <NewAddressForm placeholderAddress={billingInfo?.address} />;
  }
  return <NewAddressForm />;
}

interface BillingAddressProps {
  shippingAddress: types.Address | undefined;
  compactForm?: boolean;
  billingInfo?: types.BillingInfo | null;
}

export default function BillingAddress({ shippingAddress, compactForm, billingInfo }: BillingAddressProps) {
  const [useShippingAddress, setUseShippingAddress] = useState(!!shippingAddress);

  return (
    <div className={styles.formSection}>
      {!compactForm && <h4>Billing address</h4>}
      {shippingAddress && (
        <Chooser
          onSelect={(useShippingAddressChoice) => setUseShippingAddress(useShippingAddressChoice)}
          selectedOption={useShippingAddress}
          groups={[
            {
              options: [
                {
                  content: 'Same as shipping address',
                  value: true,
                },
                {
                  content: 'Use a different address',
                  value: false,
                },
              ],
            },
          ]}
          showRadio
          compact
        />
      )}
      <AddressForm
        shippingAddress={shippingAddress}
        billingInfo={billingInfo}
        useShippingAddress={useShippingAddress}
      />
    </div>
  );
}
