import React, { useEffect, useState } from 'react';
import styles from '../../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../../components/SubscriptionAppBar';
import Button from '../../../../../components/Button';
import { centsToDollarsNumber, mkError } from '../../../../../lib/util';
import { inAppCancellation as events } from '../../../../../lib/analytics/events';
import { useZendeskCancellationTicket } from '../../hooks/useZendesk';
import CancelConfirmationModal from '../../../components/CancelConfirmationModal';
import { useLocation, useHistory } from 'react-router-dom';
import * as gqlTypes from '../../../../../types/gql-op-types';
import { BaseInAppCancellationState } from '../../util/types';
import classNames from 'classnames';
import AppPaths from '../../../../../AppPaths';
import { useMutation } from '@apollo/client';
import { claimFreeMonthMutation, claimTwoMonthsHalfOffMutation } from '../../../../../graphql-operations';
import Loading from '../../../../../components/Loading';
import ErrorMessage from '../../../../../components/ErrorMessage';

export interface SpecialOffersState extends BaseInAppCancellationState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
  otherText?: string;
}

export enum SpecialOfferKey {
  ONE_MONTH = 'ONE_MONTH_FREE',
  TWO_MONTHS = '50_PERCENT_OFF_TWO_MONTHS',
}

export default function SpecialOffers() {
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const location = useLocation<SpecialOffersState>();
  const [error, setError] = useState('');
  const { selectedCancelReason, subscriptionCategory, otherText } = location.state;

  interface SpecialOffer {
    key: SpecialOfferKey;
    label: string;
    price: number | string;
    originalPrice?: number;
    description: string;
  }
  const [selectedOffer, setSelectedOffer] = useState<SpecialOfferKey>(SpecialOfferKey.ONE_MONTH);
  const petName = device.pet!.name;
  events.pageLoad({ petId: device.pet!.id, subscriptionCategory }, 'Special Offers');

  const offers: SpecialOffer[] = [
    {
      key: SpecialOfferKey.ONE_MONTH,
      label: 'ONE MONTH FREE',
      price: 0,
      originalPrice: centsToDollarsNumber(device.subscription?.subscriptionOption.priceInCents ?? 0),
      description: `Save $${centsToDollarsNumber(
        device.subscription?.subscriptionOption.priceInCents ?? 0,
      )} over your next two months`,
    },
    {
      key: SpecialOfferKey.TWO_MONTHS,
      label: '50% OFF NEXT TWO MONTHS',
      price: `${(centsToDollarsNumber(device.subscription?.subscriptionOption.priceInCents ?? 0) / 2).toFixed(2)}/mo`,
      description: `Save $${centsToDollarsNumber(
        device.subscription?.subscriptionOption.priceInCents ?? 0,
      )} over your next two months`,
    },
  ];
  // Sometimes, this page loads halfway down the screen. This should mitigate those instances.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [freeMonthMutation, freeMonthMutationState] = useMutation<
    gqlTypes.ECOMMERCE_claimFreeMonth,
    gqlTypes.ECOMMERCE_claimFreeMonthVariables
  >(claimFreeMonthMutation, {
    onCompleted: (data) => {
      const success = !!data.claimFreeMonth?.success;
      if (!success) {
        setError('Failed to claim free month: contact customer support.');
      } else {
        history.push({
          pathname: AppPaths.Subscription.SpecialOffersConfirmed(device.moduleId),
          state: { selectedOffer },
          search: window.location.search,
        });
      }
    },
  });

  const [claimTwoMonthsMutation, claimTwoMonthsMutationState] = useMutation<
    gqlTypes.ECOMMERCE_claimTwoMonthsHalfOffDiscount,
    gqlTypes.ECOMMERCE_claimTwoMonthsHalfOffDiscountVariables
  >(claimTwoMonthsHalfOffMutation, {
    onCompleted: (data) => {
      const success = !!data.claimTwoMonthsHalfOff?.success;
      if (!success) {
        setError(
          `Error: ${data.claimTwoMonthsHalfOff?.reasonForFailure}` ??
            'Unable to claim two months at 50% off: contact customer support.',
        );
      } else {
        history.push({
          pathname: AppPaths.Subscription.SpecialOffersConfirmed(device.moduleId),
          state: { selectedOffer },
          search: window.location.search,
        });
      }
    },
  });

  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromSpecialOffersPage,
    selectedCancelReason,
    device,
    subscriptionCategory,
    otherText,
  });

  const claimSpecialOffer = () => {
    events.specialOffersClaim({ petId: device.pet?.id, subscriptionCategory });
    if (selectedOffer === SpecialOfferKey.ONE_MONTH) {
      freeMonthMutation({
        variables: {
          input: {
            moduleId: device.moduleId,
          },
        },
      });
    } else if (selectedOffer === SpecialOfferKey.TWO_MONTHS) {
      claimTwoMonthsMutation({
        variables: {
          input: {
            recurlySubscriptionId: device.subscription?.id,
          },
        },
      });
    }
  };

  if (loading || freeMonthMutationState.loading || claimTwoMonthsMutationState.loading) {
    return <Loading />;
  }

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleCancel = () => {
    events.specialOffersContinue({ petId: device.pet?.id, subscriptionCategory });
    if (!device.subscription?.id) {
      throw new Error('Something went wrong.');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          cancellationReason: selectedCancelReason,
          additionalText: otherText,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.specialOffersBack({ petId: device.pet?.id, subscriptionCategory })}
          closeButtonAnalyticsEvent={() => events.specialOffersClose({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>Special offers just for you</h1>
          </div>
          <p>
            We want to keep you and {petName} in the pack so you have more time to explore step counting, sleep
            tracking, and {petName}'s rank.
            <br />
            <br />
            Choose the offer that works best for you:
          </p>
          {error ? (
            <ErrorMessage errors={[error]} />
          ) : (
            <div className={styles.offerContainer}>
              {offers.map((offer, idx) => {
                return (
                  <div
                    className={classNames(styles.offer, { [styles.selected]: selectedOffer === offer.key })}
                    onClick={() => setSelectedOffer(offer.key)}
                  >
                    <div className={styles.title}>
                      <div className={styles.label}>{offer.label}</div>
                      <div className={styles.offerNumberLabel}>OFFER #{idx + 1}</div>
                    </div>
                    <div className={styles.price}>
                      ${offer.price} {offer.originalPrice && <span>${offer.originalPrice}</span>}
                    </div>
                    <div className={styles.description}>{offer.description}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button className={styles.button} disabled={!selectedOffer} onClick={claimSpecialOffer}>
            Claim special offer
          </Button>
          <CancelConfirmationModal
            onContinue={() => handleCancel()}
            pageName={'Manage Membership > Special Offers'}
            petId={device.pet!.id}
            trigger={
              <Button className={styles.button} tertiary={true}>
                Continue with Cancellation
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
}
