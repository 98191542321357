import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { gqlTypes } from '../../../types';
import Button from '../../../components/Button/Button';
import { ReactComponent as Checkmark } from '../../../assets/images/checkmark_white.svg';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import SupplementPill from './components/SupplementPill';
import { supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { BillingSubscriptionState, ShippingAddress, WeightRange } from '../../../types/gql-op-types';
import Upsell from './components/Upsell/Upsell';
import { FeaturesReady } from '@growthbook/growthbook-react';
import Loading from '../../../components/Loading';

export interface SubscriptionOption {
  sku: string;
  name?: string;
  description?: string;
  priceInCents: number;
  discountedFromOriginalPriceInCents?: number;
  displayedRate?: string;
  displayedRateUnit: string;
  billingPeriodsPerTerm: number;
  weeksBetweenShipments?: number | null;
  inAppManagementName: string;
  freeTrialDays?: number | null;
  recommendedForDogWeightRangePounds?: WeightRange | null;
}

interface ShipmentDates {
  latestSkipDate: string;
  nextShipmentDate: string;
  displayDate: string;
  previewResumingShipmentDate: string;
  previewSkippingShipmentDate: string;
  previewUpdate: { sku: string; nextShipDate: string }[];
}
interface NextSupplementShipmentDetails {
  status: string | null;
  deliveryExpected?: string | null;
  trackingLink?: string | null;
  discount?: number | null;
}
export interface BillingSubscription {
  id: string;
  state: BillingSubscriptionState;
  subscriptionOption: SubscriptionOption;
  currentTermEndsAt: string;
  supplementShipmentDates?: ShipmentDates | null;
  nextSupplementShipmentDetails?: NextSupplementShipmentDetails | null;
  address?: ShippingAddress | null;
}

type ChangeType = 'canceled' | 'updated' | 'added';

interface ToastProps {
  change: ChangeType;
  multipleSubscriptionsChanged: boolean;
}

function Toast({ change, multipleSubscriptionsChanged }: ToastProps) {
  const [shown, setShown] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShown(false);
    }, 4000);
  }, []);
  return (
    <>
      {shown && (
        <div className={styles.toast}>
          <Checkmark />
          <div className={styles.toastContent}>
            Subscription{multipleSubscriptionsChanged ? 's' : ''} successfully {change}
          </div>
        </div>
      )}
    </>
  );
}

interface SplashPageState {
  toast?: { success: boolean; change: ChangeType; multipleSubscriptionsChanged: boolean };
  refetch?: boolean;
}

export default function SplashPage() {
  const displayPageName = 'Supplements';

  const [addClicked, setAddClicked] = useState(false);
  const location = useLocation<SplashPageState>();
  const [showToast, _setShowToast] = useState(location.state?.toast ? true : false);
  const toastChange = location.state?.toast?.change ?? null;
  const refetchQuery = location.state?.refetch ?? false;
  const multipleSubscriptionsChanged = location.state?.toast?.multipleSubscriptionsChanged ?? false;

  const { data, loading, error, refetch } = useQuery<gqlTypes.ECOMMERCE_getSupplementSubscriptionsQuery>(
    supplementsSubscriptionsQuery,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    if (refetchQuery) {
      refetch();
    }
  }, [refetch, refetchQuery]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    const pageName = 'Error loading Supplements';
    events.pageLoad({}, pageName);
    return (
      <>
        <div className={styles.fullWidthWrapper}>
          <SubscriptionAppBar
            title={displayPageName}
            backButtonExitsWebview={true}
            backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
            closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
          />
          <p className={styles.unauthorized}>
            Error: {error.message}. Please contact support@tryfi.com if this persists.
          </p>
        </div>
      </>
    );
  }

  const hasEverPurchasedSupplementSubscription = data?.currentUser.hasPurchasedSupplementSubscription;
  const hideFree = !!hasEverPurchasedSupplementSubscription;

  const supplementSubscriptions: BillingSubscription[] = data?.currentUser.billingAccount?.subscriptions ?? [];
  const activeSupplementSubscriptions = supplementSubscriptions?.filter(
    (sub) => sub.state === BillingSubscriptionState.ACTIVE,
  );
  // Sort by nextShipmentDate ascending
  const sortedSubscriptions = activeSupplementSubscriptions.sort((a: any, b: any) =>
    a.supplementShipmentDates.displayDate.localeCompare(b.supplementShipmentDates.displayDate),
  );

  const componentToReturn = () => {
    function MainPage() {
      const pageName = 'Main Page';
      events.pageLoad({}, pageName);

      return (
        <>
          <div className={styles.fullWidthWrapper}>
            <SubscriptionAppBar
              title={displayPageName}
              backButtonExitsWebview={true}
              backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
              closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
              noCloseButton={true}
            />
            <div className={styles.subscriptionContainer}>
              <div className={styles.headerContainer}>
                <h1>8-in-1 Multivitamin</h1>
                <h3>Upcoming shipments</h3>
              </div>
              {sortedSubscriptions.map((subscription: any, idx: number) => (
                <React.Fragment key={idx}>
                  <p className={styles.subscriptionNumber}>Subscription #{idx + 1}</p>

                  <SupplementPill
                    hasMultipleSubscriptions={sortedSubscriptions.length > 1}
                    subscription={subscription}
                    subscriptionDetails={subscription}
                    version={'informational'}
                    pendingPlan={subscription.pendingChanges ? subscription.pendingChanges.subscriptionOption : null}
                    key={subscription.id}
                  />
                </React.Fragment>
              ))}
            </div>

            {showToast && toastChange && (
              <Toast change={toastChange} multipleSubscriptionsChanged={multipleSubscriptionsChanged} />
            )}
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                onClick={() => {
                  events.addSupplementsTapped({});
                  setAddClicked(true);
                }}
              >
                Add Supplements
              </Button>
              {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
              <div className={styles.buttonSpacer}></div>
            </div>
          </div>
        </>
      );
    }

    if (!sortedSubscriptions.length) {
      return (
        <FeaturesReady>
          <Upsell referrer="upsell" closeUpsell={() => setAddClicked(false)} hideFree={hideFree} />
        </FeaturesReady>
      );
    }

    if (addClicked) {
      return <Upsell referrer="add" closeUpsell={() => setAddClicked(false)} hideFree={hideFree} />;
    }

    return <MainPage />;
  };

  return <> {componentToReturn()}</>;
}
