import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateID } from '../lib/util';
import * as types from '../types';
import {
  baseCartReducers,
  couponCartReducers,
  giftCardRedemptionCartReducers,
  referralCodeCartReducers,
} from './baseCart';

interface IAddSingleLineCartItem {
  sku: string;
  quantity?: number;
}

interface IAddBundleCartItem extends IAddSingleLineCartItem {
  subscriptionSku: string;
  addonSkus?: string[];
  quantity?: number;
}

interface IAddGiftCardCartItem extends IAddSingleLineCartItem {
  giftCardDelivery: types.GiftCardDelivery;
}

interface IUpdateCartItemQuantity {
  id: string;
  quantity: number;
}

interface IResetCart {
  resetCoupon?: boolean;
}

export const cartInitialState: types.StoreCart = {
  cartItems: {},
};

const name = 'storeCart' as const;
export const { actions: cartActions, reducer: cartReducer } = createSlice({
  name,
  initialState: cartInitialState,
  reducers: {
    ...baseCartReducers,
    ...couponCartReducers,
    ...giftCardRedemptionCartReducers,
    ...referralCodeCartReducers,
    addSingleLineCartItem(cart: types.StoreCart, { payload }: PayloadAction<IAddSingleLineCartItem>) {
      const singleLineCartItem: types.SingleLineCartItem = {
        cartItemId: generateID(),
        lineItem: {
          lineItemId: generateID(),
          sku: payload.sku,
        },
        // Use quantity if it was provided or fallback to quantity of '1'
        quantity: payload.quantity && payload.quantity > 0 ? payload.quantity : 1,
      };

      return {
        ...cart,
        cartItems: {
          ...cart.cartItems,
          [singleLineCartItem.cartItemId]: singleLineCartItem,
        },
      };
    },
    addBundleCartItem(cart: types.StoreCart, { payload }: PayloadAction<IAddBundleCartItem>) {
      const bundleCartItem: types.BundleCartItem = {
        cartItemId: generateID(),
        lineItem: {
          lineItemId: generateID(),
          sku: payload.sku,
        },
        subscriptionLineItem: {
          lineItemId: generateID(),
          sku: payload.subscriptionSku,
        },
        addonLineItems: payload.addonSkus?.map((sku) => ({
          lineItemId: generateID(),
          sku,
        })),
        // Use quantity if it was provided or fallback to quantity of '1'
        quantity: payload.quantity && payload.quantity > 0 ? payload.quantity : 1,
      };

      return {
        ...cart,
        cartItems: {
          ...cart.cartItems,
          [bundleCartItem.cartItemId]: bundleCartItem,
        },
      };
    },
    addGiftCardCartItem(cart: types.StoreCart, { payload }: PayloadAction<IAddGiftCardCartItem>) {
      const giftCardCartItem: types.GiftCardCartItem = {
        cartItemId: generateID(),
        lineItem: {
          lineItemId: generateID(),
          sku: payload.sku,
        },
        giftCardDelivery: payload.giftCardDelivery,

        quantity: 1,
      };

      return {
        ...cart,
        cartItems: {
          ...cart.cartItems,
          [giftCardCartItem.cartItemId]: giftCardCartItem,
        },
      };
    },
    updateCartItemQuantity(cart: types.StoreCart, { payload }: PayloadAction<IUpdateCartItemQuantity>) {
      if (cart.cartItems[payload.id]) {
        cart.cartItems[payload.id].quantity = payload.quantity;
      }
    },

    resetCart(cart: types.StoreCart, { payload }: PayloadAction<IResetCart>) {
      let newState = { ...cartInitialState };
      if (!payload.resetCoupon) {
        const couponCode = cart.couponCode;
        newState = { ...newState, couponCode };
      }
      return newState;
    },
  },
});
