import React, { useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { mkError } from '../../../../lib/util';
import * as gqlTypes from '../../../../types/gql-op-types';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import Chooser from '../../../../components/Chooser';
import { useHistory, useLocation } from 'react-router-dom';
import AppPaths from '../../../../AppPaths';
import { BaseInAppCancellationState } from '../util/types';

export default function TellUsMore() {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const [selectedCancelReason, setSelectedCancelReason] = useState<gqlTypes.zendeskCancellationReason | undefined>(
    undefined,
  );

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  events.pageLoad({ petId: device.pet!.id, subscriptionCategory }, 'Tell Us More');

  const handleContinue = () => {
    if (!selectedCancelReason) {
      throw new Error('Something went wrong.');
    }
    events.tellUsMoreContinue({
      petId: device.pet?.id,
      cancellationReason: selectedCancelReason,
      subscriptionCategory,
    });
    history.push({
      pathname: AppPaths.Subscription.ContactCx(device.moduleId),
      state: { selectedCancelReason, subscriptionCategory },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.tellUsMoreBack({ petId: device.pet?.id, subscriptionCategory })}
          closeButtonAnalyticsEvent={() => events.tellUsMoreClose({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Tell us more</h1>
          <p>To help us guide you in the right direction, please select one of the options below.</p>

          <div className={styles.reasonHeader}>Reason for cancellation:</div>
          <div className={styles.content}>
            <div className={styles.content}>
              <Chooser
                onSelect={(selectedReason) => {
                  setSelectedCancelReason(selectedReason);
                }}
                selectedOption={selectedCancelReason}
                groups={[
                  {
                    options: [
                      {
                        content: "My dog's location is not correct",
                        value: gqlTypes.zendeskCancellationReason.LOCATION_REPORTS_INCORRECT,
                      },
                      {
                        content: 'The app took too long to notify me',
                        value: gqlTypes.zendeskCancellationReason.TOOK_TOO_LONG_TO_NOTIFY,
                      },
                      {
                        content: "The collar's battery drains too quickly",
                        value: gqlTypes.zendeskCancellationReason.BATTERY_DRAINS_TOO_QUICKLY,
                      },
                      {
                        content: 'Other',
                        value: gqlTypes.zendeskCancellationReason.OTHER,
                      },
                    ],
                  },
                ]}
                showRadio
                compact
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} disabled={selectedCancelReason === undefined} onClick={handleContinue}>
            Continue
          </Button>
          {/* styles.buttonSpacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
