import { CardElement, Elements, useRecurly } from '@recurly/react-recurly';
import React, { useCallback, useState } from 'react';
import { gqlTypes } from '../../../../types';
import ActionContainer from '../../../../components/ActionContainer';
import FiRecurlyProvider from '../../../../components/FiRecurlyProvider';
import Button from '../../../../components/Button';
import ErrorMessages, { getCustomerMessageFromApolloError, UserFacingError } from '../../../../lib/errors';
import styles from '../../../../styles/form.module.scss';
import * as types from '../../../../types';
import BillingAddress from './BillingAddress';
import classNames from 'classnames';

export type UpdateCreditCardBillingInfoFunction = (options: {
  input: gqlTypes.BillingAccountInput;
  firstName: string;
  lastName: string;
}) => Promise<void>;

interface IEditCreditCardBillingInfoProps {
  shippingAddress?: types.Address;
  updateBillingInfo: UpdateCreditCardBillingInfoFunction;
  billingInfo?: types.BillingInfo | null;
  actionText?: string;
  submitting: boolean;
  setError: (message: string) => void;
  clearError: () => void;
  setSuccess?: (success: boolean) => void;
  onCancelEdit?: () => void;
  compactForm?: boolean;
}

function formatCreditCardNumber(lastFourDigits: number): string {
  const str = lastFourDigits.toString();
  return str.padStart(12, '*');
}
function formatExpiration(month: number, year: number): string {
  return `${month} / ${year.toString().slice(-2)}`;
}

function CreditCardForm({
  shippingAddress,
  updateBillingInfo,
  billingInfo,
  actionText,
  submitting: parentSubmitting,
  setError,
  clearError,
  setSuccess,
  onCancelEdit,
  compactForm,
}: IEditCreditCardBillingInfoProps) {
  const recurly = useRecurly();
  const formRef = React.createRef<HTMLFormElement>();

  const [firstName, setFirstName] = useState<string>(billingInfo?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(billingInfo?.lastName ?? '');

  const [tokenSubmitting, setTokenSubmitting] = useState(false);
  const submitting = tokenSubmitting || parentSubmitting;

  let style = {};
  if (billingInfo?.paymentInfo.__typename === 'ObfuscatedCardInfo') {
    const formattedNumber = formatCreditCardNumber(billingInfo?.paymentInfo.lastFour);
    const formattedExpiration = formatExpiration(billingInfo?.paymentInfo.month, billingInfo?.paymentInfo.year);

    style = {
      placeholder: {
        content: {
          number: formattedNumber,
          expiry: formattedExpiration,
        },
      },
    };
  }

  const handleAddPaymentDetails: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();

      if (submitting || !formRef.current) {
        return;
      }

      clearError();
      setTokenSubmitting(true);

      recurly.token(formRef.current, (recurlyError, token) => {
        if (recurlyError) {
          const messages = [];
          if (recurlyError.fields?.includes('first_name') || recurlyError.fields?.includes('last_name')) {
            messages.push(`Please enter a valid first and last name`);
          }

          if (recurlyError.fields?.includes('number')) {
            messages.push(`Invalid credit card number`);
          }

          if (recurlyError.fields?.includes('month') || recurlyError.fields?.includes('year')) {
            messages.push(`Invalid expiration date`);
          }

          if (recurlyError.fields?.includes('cvv')) {
            messages.push(`Invalid CVV`);
          }

          if (messages.length === 0) {
            messages.push(recurlyError.message);
          }

          setError(messages.join(', '));
        } else {
          updateBillingInfo({
            input: { billingInfo: { token: token.id } },
            firstName,
            lastName,
          })
            .then(() => {
              setSuccess && setSuccess(true);
            })
            .catch((err) => {
              const message =
                err instanceof UserFacingError
                  ? err.message
                  : getCustomerMessageFromApolloError(err) || ErrorMessages.INPUT_ERROR;

              setError(message);
            });
        }

        setTokenSubmitting(false);
      });
    },
    [recurly, updateBillingInfo, submitting, clearError, setError, formRef, firstName, lastName, setSuccess],
  );

  return (
    <form
      className={classNames(styles.form, {
        [styles.compact]: compactForm,
      })}
      onSubmit={handleAddPaymentDetails}
      ref={formRef}
    >
      <div className={styles.formSection}>
        {!compactForm && <h4>Credit card</h4>}
        <input type="hidden" name="recurly-token" data-recurly="token" />
        <div className={styles.formRow}>
          <input
            type="text"
            placeholder="First name"
            autoComplete="cc-given-name"
            data-recurly="first_name"
            onChange={(event) => setFirstName(event.target.value)}
            value={firstName}
            data-hj-whitelist
          />
          <input
            type="text"
            placeholder="Last name"
            autoComplete="cc-family-name"
            data-recurly="last_name"
            onChange={(event) => setLastName(event.target.value)}
            value={lastName}
            data-hj-whitelist
          />
        </div>
        <CardElement style={style} />
      </div>
      <BillingAddress shippingAddress={shippingAddress} billingInfo={billingInfo} compactForm={compactForm} />
      <ActionContainer>
        {onCancelEdit && (
          <Button secondary disabled={submitting} onClick={onCancelEdit}>
            Cancel
          </Button>
        )}
        <Button disabled={submitting} type="submit">
          {actionText || 'Save and exit'}
        </Button>
      </ActionContainer>
    </form>
  );
}

export default function EditCreditCardBillingInfo(props: IEditCreditCardBillingInfoProps) {
  return (
    <FiRecurlyProvider>
      <Elements>
        <CreditCardForm {...props} />
      </Elements>
    </FiRecurlyProvider>
  );
}
