import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/images/icons/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { usePreviousPath, useAnalyticsEvents } from '../../contexts/WebViewAppBarContext';
import styles from './WebViewAppBar.module.scss';

interface WebViewAppBarProps {
  darkBackground?: boolean;
}

export default function WebViewAppBar({ darkBackground }: WebViewAppBarProps) {
  const queryParameters = new URLSearchParams(window.location.search);
  const hideAppBar = queryParameters.get('hideAppBar') === 'true';
  const hideCloseButton = queryParameters.get('hideCloseButton') === 'true';
  const history = useHistory();
  const previousPath = usePreviousPath();
  const analyticsEvents = useAnalyticsEvents();

  // Dynamically updating viewport properties only when displaying this S3 webview app bar as to not mess
  // with anything that's currently live.
  // TODO: Consider moving this to public/index.html
  // https://app.asana.com/0/0/1202770270793173/f
  useEffect(() => {
    const viewport = document.getElementById('viewport');
    if (!viewport) {
      return;
    }

    const viewportFit = 'viewport-fit=cover';
    const currentContent = viewport.getAttribute('content');
    if (!currentContent || currentContent.includes(viewportFit)) {
      return;
    }

    viewport.setAttribute('content', `${currentContent}, ${viewportFit}`);
  }, []);

  if (hideAppBar) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.darkBackground]: darkBackground,
      })}
    >
      <div className={styles.left}>
        {previousPath && (
          <div
            className={styles.button}
            onClick={() => {
              analyticsEvents?.backButton?.();
              history.replace({ pathname: previousPath, search: window.location.search });
            }}
          >
            <BackIcon />
          </div>
        )}
      </div>
      {!hideCloseButton && (
        <div className={styles.right}>
          <div
            className={styles.button}
            onClick={() => {
              analyticsEvents?.closeButton?.();
              window.open('fi://closeappview');
            }}
          >
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  );
}
