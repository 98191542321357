import * as qs from 'query-string';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { exactVariantForOptions } from '../../../lib/product';
import * as types from '../../../types';
import {
  getNanoVariantPetAgeMonths,
  getNanoVariantPetBreed,
  showNanoVariant,
} from '../../../components/NanoBanner/NanoBanner';
import { getFiGrowthBook } from '../../../lib/growthbook';
import {
  getNanoReferralPetGenderCookie,
  getNanoReferralPetWeightCookie,
} from '../../../lib/util/nanoReferralPetNameCookie';
import { getSizingGuideRecommendation } from '../components/SizingGuide/DynamicSizingGuide';
import { SizingGuideItem } from '../components/SizingGuide/Dataset';

interface ProductDetailsQueryParams {
  color?: types.ColorOption;
  size?: types.SizeOption;
  type?: types.CollarTypeOption;
}

function useDefaultVariantOptions(variants: types.IVariant[]): types.IVariantOptions {
  const location = useLocation();

  const { initialCollarType, initialColor, initialSize } = useMemo(() => {
    const queryParams: ProductDetailsQueryParams = qs.parse(location.search);
    return {
      initialCollarType: queryParams.type,
      initialColor: queryParams.color,
      initialSize: queryParams.size,
    };
  }, [location.search]);

  return useMemo(() => {
    const backupOptions = variants[0].options;

    const preferredTypes = [initialCollarType, types.CollarTypeOption.Standard];
    const availablePreferredType = preferredTypes.find((t) => variants.some((v) => v.options.collarType === t));
    const collarType = availablePreferredType ?? backupOptions.collarType;

    const preferredColors = [initialColor, types.ColorOption.Gray].filter((colorOption) => !!colorOption);
    const availablePreferredColor = preferredColors.find((c) => variants.some((v) => v.options.color === c));
    const color = availablePreferredColor ?? backupOptions.color;

    const preferredSizes = [initialSize, types.SizeOption.Medium];
    const availablePreferredSize = preferredSizes.find((s) => variants.some((v) => v.options.size === s));
    const size = availablePreferredSize ?? backupOptions.size;

    return {
      collarType,
      color,
      size,
    };
  }, [initialCollarType, initialColor, initialSize, variants]);
}

function getSizeOption(item: SizingGuideItem): types.SizeOption {
  const sizes = {
    [types.SizeOption.XSmall]: item.XS,
    [types.SizeOption.Small]: item.S,
    [types.SizeOption.Medium]: item.M,
    [types.SizeOption.Large]: item.L,
    [types.SizeOption.XLarge]: item.XL,
  };

  const largestSize = Object.keys(sizes).reduce((a, b) =>
    sizes[a as types.SizeOption] > sizes[b as types.SizeOption] ? a : b,
  );

  return largestSize as types.SizeOption;
}

export function getNanoVariantOptions(): types.IVariantOptions {
  const recommendation = getSizingGuideRecommendation(
    Number(getNanoReferralPetWeightCookie()) || 30,
    getNanoVariantPetBreed() || 'Other',
    getNanoVariantPetAgeMonths() <= 6,
  );

  return {
    collarType: types.CollarTypeOption.Standard,
    color: getNanoReferralPetGenderCookie() === 'male' ? types.ColorOption.Blue : types.ColorOption.Pink,
    size: getSizeOption(recommendation),
  };
}

interface VariantSelectorProps {
  onColorChange: (newVariant: types.IVariant) => void;
  onSelectedVariantChange: (newVariant: types.IVariant) => void;
  onSizeChange: (newVariant: types.IVariant) => void;
  variants: types.IVariant[];
}

export default function useVariantSelector({
  onColorChange,
  onSelectedVariantChange,
  onSizeChange,
  variants,
}: VariantSelectorProps) {
  const history = useHistory();
  const defaultOptions = useDefaultVariantOptions(variants);
  const nanoVariantEnabled = showNanoVariant();
  const useNanoDefaultOptions = getFiGrowthBook().getFeatureValue(
    'nano-registration-size-suggestion-experiment',
    false,
  );
  const overrideOptionsForNano = useNanoDefaultOptions && nanoVariantEnabled;
  const options = overrideOptionsForNano ? getNanoVariantOptions() : defaultOptions;

  const [selectedVariant, setSelectedVariant] = useState<types.IVariant>(
    exactVariantForOptions(variants, options) ?? variants[0],
  );
  const location = useLocation();
  const queryParams: ProductDetailsQueryParams = qs.parse(location.search);
  const [hasUpdatedSize, setHasUpdatedSize] = useState<boolean>(!!queryParams.size || overrideOptionsForNano);

  const changeVariantOptions = useCallback(
    (o: Partial<types.IVariantOptions>) => {
      const newOptions = { ...selectedVariant?.options, ...o };
      const newVariant = exactVariantForOptions(variants, newOptions) ?? variants[0];

      if (!o.color && o.size) {
        setHasUpdatedSize(true);
      }

      if (newVariant.options.color !== selectedVariant?.options.color) {
        onColorChange(newVariant);
      }

      if (newVariant.options.size !== selectedVariant?.options.size) {
        onSizeChange(newVariant);
      }

      setSelectedVariant(newVariant);

      const updatedQueryParams = { ...queryParams, ...newOptions };
      const queryString = qs.stringify(updatedQueryParams);
      history.replace({ search: queryString, state: { noScroll: true } });
    },
    [queryParams, history, onColorChange, onSizeChange, selectedVariant?.options, variants],
  );

  useEffect(() => {
    onSelectedVariantChange(selectedVariant);
  }, [onSelectedVariantChange, selectedVariant]);

  /**
   * If we no longer have a valid variant, switch into new default options. This is useful where the selected
   * options might be for S3 options, but then toggled to S2 that do not have the same variants (e.g. scribble-out
   * color or XS size). This will keep any applicable options, but choose new defaults for the ones that are not
   * applicable.
   */
  useEffect(() => {
    if (!variants.find((variant) => variant.sku === selectedVariant.sku)) {
      changeVariantOptions(defaultOptions);
    }
  }, [changeVariantOptions, defaultOptions, selectedVariant, variants]);

  return {
    changeVariantOptions,
    selectedVariant,
    hasUpdatedSize,
  };
}
