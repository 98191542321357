import {
  ApplePayError,
  ApplePayLineItem,
  ApplePayRecurringPaymentDateUnit,
  ApplePayRecurringPaymentRequest,
} from '@recurly/recurly-js';
import { useCallback, useContext, useMemo } from 'react';
import useShippingOptions from '../../hooks/useShippingOptions';
import CheckoutContext from '../../lib/CheckoutContext';
import { FiApplePaySelectionUpdate } from '../../lib/RecurlyProvider';
import { useCartMode } from '../../lib/cartModes';
import { APPLE_PAY_ERROR_CODE_CUSTOM_API, APPLE_PAY_ERROR_CODE_SHIPPING, logInternalError } from '../../lib/errors';
import { priceCartForCheckout } from '../../lib/pricing';
import { centsToDollars } from '../../lib/util';
import * as types from '../../types';
import ApplePayPaymentState from './ApplePayPaymentState';
import { IApplePayEvents } from './Events';
import { DateTime } from 'luxon';
import useApplePayRecurring from '../../hooks/useApplePayRecurring';

const APPLE_PAY_LABEL = 'Fi';
const RECURRING_BILLING_LABEL = 'Fi Membership';

interface UpdatePricingHookProps {
  events: IApplePayEvents;
  paymentState: ApplePayPaymentState;
  setCartPricing: (cartPricing: types.CartPricing) => void;
}

interface PaymentSheetLineItems {
  lineItems: ApplePayLineItem[];
  finalTotalLineItem: ApplePayLineItem;
  recurringPaymentRequest?: ApplePayRecurringPaymentRequest;
}

interface CallbackProps {
  updateShippingMethods?: boolean;
}

interface RecurringChargeForApplePay {
  interval: ApplePayRecurringPaymentDateUnit;
  intervalCount: number;
  amountInCents: number;
  quantity: number;
  freeTrialDays?: number | undefined;
}

/**
 * Maps our shipping options to the Apple Pay shipping methods interface for display on the Apple Pay payment sheet.
 */
function shippingOptionToApplePayShippingMethod(
  shippingOption: types.IShippingOption,
): ApplePayJS.ApplePayShippingMethod {
  return {
    amount: centsToDollars(shippingOption.priceInCents),
    label: shippingOption.name,
    identifier: shippingOption.code,
    detail: shippingOption.detail,
  };
}

function recurringChargeLabelWithQuantity(label: string, quantity: number): string {
  return quantity > 1 ? `${label} x${quantity}` : label;
}

/**
 * Apple Pay recurringPaymentRequest API only supports a single recurring charge at a time. If there are multiple
 * recurring charges in the cart, we cannot use the recurringPaymentRequest API.
 * https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/3955946-recurringpaymentrequest
 *
 * @returns an Apple Pay recurringPaymentRequest object if there is only one recurring charge in the cart; undefined
 * otherwise.
 */
function recurringPaymentRequestFromRecurringCharges(
  recurringCharges: RecurringChargeForApplePay[],
): ApplePayRecurringPaymentRequest | undefined {
  if (recurringCharges.length !== 1) {
    return undefined;
  }

  const recurringCharge = recurringCharges[0];

  let subscriptionStartDateTime = DateTime.utc();
  let trialBilling: ApplePayLineItem | undefined = undefined;
  if (recurringCharge.freeTrialDays) {
    subscriptionStartDateTime = subscriptionStartDateTime.plus({ days: recurringCharge.freeTrialDays });

    trialBilling = {
      label: `${recurringCharge.freeTrialDays} day trial`,
      amount: centsToDollars(0),
      paymentTiming: 'recurring',
    };
  }

  return {
    paymentDescription: RECURRING_BILLING_LABEL,
    trialBilling,
    regularBilling: {
      label: recurringChargeLabelWithQuantity(RECURRING_BILLING_LABEL, recurringCharge.quantity),
      amount: centsToDollars(recurringCharge.amountInCents),
      paymentTiming: 'recurring',
      recurringPaymentStartDate: subscriptionStartDateTime.toJSDate(),
      recurringPaymentIntervalUnit: recurringCharge.interval,
      recurringPaymentIntervalCount: recurringCharge.intervalCount,
    },
  };
}

function applePayLineItemForRecurringCharge(recurringCharge: RecurringChargeForApplePay): ApplePayLineItem {
  const label = `${recurringCharge.intervalCount} ${recurringCharge.interval} membership`;

  let startDateTime = DateTime.utc();
  if (recurringCharge.freeTrialDays) {
    startDateTime = startDateTime.plus({ days: recurringCharge.freeTrialDays });
  }

  return {
    label: recurringChargeLabelWithQuantity(label, recurringCharge.quantity),
    amount: centsToDollars(recurringCharge.amountInCents),
    paymentTiming: 'recurring',
    recurringPaymentStartDate: startDateTime.toJSDate(),
    recurringPaymentIntervalUnit: recurringCharge.interval,
    recurringPaymentIntervalCount: recurringCharge.intervalCount,
  };
}

/**
 * After calling the Fi pricing API, we can use this method to build the line items that will be displayed on the
 * Apple Pay payment sheet with that pricing information.
 *
 * @returns lineItems An array of line items to display on the Apple Pay payment sheet.
 * @returns finalTotalLineItem The final total to display on the Apple Pay payment sheet.
 */
export function paymentSheetLineItems(
  cartPricing: types.CartPricing,
  useRecurringPaymentRequest: boolean,
  paymentState?: ApplePayPaymentState,
): PaymentSheetLineItems {
  const lineItems: ApplePayLineItem[] = [];

  // When we start using Apple Pay recurringPaymentRequest API, we'll want to display itemized charges to the user
  let recurringCharges: RecurringChargeForApplePay[] = [];
  if (useRecurringPaymentRequest) {
    recurringCharges = (cartPricing.totalRecurringCharges ?? []).map((recurringCharge) => {
      let interval = recurringCharge.interval;
      let intervalCount = recurringCharge.intervalCount;

      // Apple Pay recurringPaymentRequest does not list 'week' as a valid unit so convert it to days
      // https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrecurringpaymentdateunit
      if (interval === 'week') {
        interval = 'day';
        intervalCount *= 7;
      }

      return {
        ...recurringCharge,
        // Explicitly cast the type here. ApplePayRecurringPaymentDateUnit type definition is currently incorrect.
        // It only lists 'month' and 'year' as valid values, but Apple Pay does support 'day' units.
        interval: interval as ApplePayRecurringPaymentDateUnit,
        intervalCount,
      };
    });

    const sortedRecurringCharges = recurringCharges.sort((a, b) => {
      if (a.interval === b.interval) {
        return a.intervalCount - b.intervalCount;
      }

      return a.interval.localeCompare(b.interval);
    });
    for (const recurringCharge of sortedRecurringCharges) {
      lineItems.push(applePayLineItemForRecurringCharge(recurringCharge));
    }

    if (cartPricing.activationFeesInCents) {
      lineItems.push({
        label: 'One time activation fee',
        amount: centsToDollars(cartPricing.activationFeesInCents),
      });
    }
  }

  lineItems.push({
    label: 'Subtotal',
    amount: centsToDollars(cartPricing.subtotalInCents),
  });

  if (paymentState?.selectedShippingCode) {
    lineItems.push({
      label: 'Shipping',
      amount: centsToDollars(cartPricing.shippingInCents ?? 0),
    });
  }

  if (cartPricing.appliedDiscountInCents) {
    lineItems.push({
      label: 'Discount',
      amount: `-${centsToDollars(cartPricing.appliedDiscountInCents)}`,
    });
  }

  if (cartPricing.appliedGiftCardAmountInCents) {
    lineItems.push({
      label: 'Gift Card',
      amount: `-${centsToDollars(cartPricing.appliedGiftCardAmountInCents)}`,
    });
  }

  if (cartPricing.upgradeCreditAmountInCents) {
    lineItems.push({
      label: 'Series 2 Credit',
      amount: `-${centsToDollars(cartPricing.upgradeCreditAmountInCents)}`,
    });
  }

  if (cartPricing.appliedAccountBalanceInCents) {
    lineItems.push({
      label: 'Account Balance',
      amount: `-${centsToDollars(cartPricing.appliedAccountBalanceInCents)}`,
    });
  }

  lineItems.push({
    label: 'Estimated Tax',
    amount: centsToDollars(cartPricing.taxInCents ?? 0),
  });

  const recurringPaymentRequest = recurringPaymentRequestFromRecurringCharges(recurringCharges);

  return {
    lineItems,
    finalTotalLineItem: {
      label: APPLE_PAY_LABEL,
      amount: centsToDollars(cartPricing.totalInCents),
    },
    recurringPaymentRequest,
  };
}

/**
 * Returns any errors we can display when the user selects their shipping address on the Apple Pay payment sheet.
 * At that time, we only have access to the country and postal code.
 */
function errorsFromPaymentState(paymentState: ApplePayPaymentState): ApplePayError[] {
  const errors: ApplePayError[] = [];

  if (paymentState.country !== 'US') {
    errors.push({
      code: APPLE_PAY_ERROR_CODE_SHIPPING,
      contactField: 'country',
      message: 'We do not currently ship outside of the US',
    });
  }

  return errors;
}

/**
 * @returns a callback that can be used to update the Apple Pay payment sheet with the latest pricing information
 *
 * This callback is intended to be called when a user makes a selection to shipping address or shipping method
 * on the Apple Pay payment sheet. The callback returns an object with errors to display on the Apple Pay payment
 * sheet, if any, or updates to the payment sheet line items.
 */
export default function useUpdatePaymentSheet({
  events,
  paymentState,
  setCartPricing,
}: UpdatePricingHookProps): (callbackProps?: CallbackProps) => Promise<FiApplePaySelectionUpdate> {
  const useRecurringPaymentRequest = useApplePayRecurring();

  const { cart } = useContext(CheckoutContext);
  const { checkoutType } = useCartMode();

  const shippingOptions = useShippingOptions();

  const shippingMethods = useMemo(
    () => shippingOptions?.map(shippingOptionToApplePayShippingMethod) ?? [],
    [shippingOptions],
  );

  return useCallback(
    async ({ updateShippingMethods }: CallbackProps = {}) => {
      const addressErrors = errorsFromPaymentState(paymentState);
      if (addressErrors.length > 0) {
        return { errors: addressErrors };
      }

      try {
        const updatedCartPricing = await priceCartForCheckout(cart, {
          checkoutType,
          address: paymentState.toAddress() || undefined,
          shippingCode: paymentState.selectedShippingCode || undefined,
        });

        setCartPricing(updatedCartPricing);

        const { finalTotalLineItem, lineItems, recurringPaymentRequest } = paymentSheetLineItems(
          updatedCartPricing,
          useRecurringPaymentRequest,
          paymentState,
        );

        return {
          newTotal: finalTotalLineItem,
          newLineItems: lineItems,
          newShippingMethods: updateShippingMethods ? shippingMethods : undefined,
          newRecurringPaymentRequest: useRecurringPaymentRequest ? recurringPaymentRequest : undefined,
        };
      } catch (err) {
        // Error when trying to calculate pricing for the selected address and shipping method
        events.applePayError(err.message);
        logInternalError(err);

        return {
          customErrors: [
            {
              code: APPLE_PAY_ERROR_CODE_CUSTOM_API,
              message: err.message,
            },
          ],
        };
      }
    },
    [cart, checkoutType, events, paymentState, setCartPricing, shippingMethods, useRecurringPaymentRequest],
  );
}
