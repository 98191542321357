import React, { useState } from 'react';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useZendeskTroubleshootingTicket } from '../../ManageSubscription/Collar/hooks/useZendesk';
import * as gqlTypes from '../../../types/gql-op-types';
import { troubleshoot as events } from '../../../lib/analytics/events';
import { mkError } from '../../../lib/util';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import { TextArea } from '../../../components/TextArea/TextArea';
import Button from '../../../components/Button';

export default function TroubleshootOtherReason() {
  const { device } = usePurchaseSubscriptionContext();
  const [otherText, setOtherText] = useState('');
  const [submitted, setSubmitted] = useState(false);

  events.pageLoad({ petId: device.pet?.id }, 'Other Reason');

  const { mutation, loading } = useZendeskTroubleshootingTicket({
    analyticsEvent: events.submittedOtherTroubleshootingReason,
    selectedTroubleshootingReason: gqlTypes.zendeskCancellationReason.OTHER,
    device,
    otherText,
  });

  if (loading) {
    return loading;
  }

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleSubmit = () => {
    if (!otherText || !device.subscription?.id) {
      throw new Error('Something went wrong');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          troubleshootingReason: gqlTypes.zendeskCancellationReason.OTHER,
          additionalText: otherText,
        },
      },
    });
    setSubmitted(true);
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.otherReasonBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.otherReasonClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Let us know what happened</h1>
          <div className={styles.content}>
            <p>
              Can you please describe your issue? We’ll have a Customer Success Agent contact you to discuss next steps.
            </p>
            <div className={styles.textArea}>
              <TextArea
                onChange={(value) => {
                  value && setOtherText(value);
                }}
                rows={12}
                placeholderText="Type your response here..."
                disabled={submitted}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} disabled={submitted} onClick={handleSubmit}>
            {submitted ? 'Submitted' : 'Submit'}
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
