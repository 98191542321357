import React, { useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../AppPaths';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import Chooser from '../../../../components/Chooser';
import { useQuery } from '@apollo/client';
import { getLoadingOrErrorElement, mkError } from '../../../../lib/util';
import { petsWithActiveSubscriptionQuery, hasMultidogDiscountQuery } from '../../../../graphql-operations';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { gqlTypes } from '../../../../types';
import { SeriesAbbrev } from '../../../../types/Series';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { maybeTruncatePetName } from '../util/helpers';
import { BaseInAppCancellationState } from '../util/types';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';

export default function CancelSubscription() {
  const history = useHistory();
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const [selectedCancelReason, setSelectedCancelReason] = useState<gqlTypes.zendeskCancellationReason | undefined>(
    undefined,
  );

  const showUpgradeForModuleVersions: SeriesAbbrev[] = ['S1', 'S2'];
  const provideReplaceOptionForModuleVersions: SeriesAbbrev[] = ['S3'];
  const collarModel = device.currentCollarModel as SeriesAbbrev;

  const petName = device.pet?.name ? device.pet.name : 'your dog';
  const shortPetName = maybeTruncatePetName(petName); // 'your dog' will not be truncated as it is < 16 characters

  const query = useQuery<gqlTypes.ECOMMERCE_petsWithActiveSubscription>(petsWithActiveSubscriptionQuery, {});
  const multidogDiscountQuery = useQuery<gqlTypes.ECOMMERCE_hasMultidogDiscount>(hasMultidogDiscountQuery, {});

  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromReasonPage,
    selectedCancelReason: selectedCancelReason ?? gqlTypes.zendeskCancellationReason.OTHER,
    device,
    subscriptionCategory,
  });
  if (loading) {
    return loading;
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(
    query.loading || multidogDiscountQuery.loading,
    query.error || multidogDiscountQuery.error,
  );
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  // Cancellation reasons that result in navigation to another page (vs immediate cancellation)
  const navigationReasons = [
    gqlTypes.zendeskCancellationReason.OTHER,
    gqlTypes.zendeskCancellationReason.COLLAR_DOES_NOT_WORK_PROPERLY,
    gqlTypes.zendeskCancellationReason.COLLAR_IS_PHYSICALLY_BROKEN,
    gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
    gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY,
    gqlTypes.zendeskCancellationReason.BILLING_ERROR,
    gqlTypes.zendeskCancellationReason.DOG_WILL_NOT_WEAR_COLLAR,
  ];

  const handleNavigate = () => {
    if (!selectedCancelReason || !device.subscription?.id) {
      throw new Error('Something went wrong.');
    }

    events.cancellationReasonSelectedAndClicked({
      petId: device.pet?.id,
      cancellationReason: selectedCancelReason,
      subscriptionCategory,
    });

    if (selectedCancelReason === gqlTypes.zendeskCancellationReason.OTHER) {
      history.push({
        pathname: AppPaths.Subscription.CancelOtherReason(device.moduleId),
        search: window.location.search,
        state: { subscriptionCategory },
      });
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.COLLAR_DOES_NOT_WORK_PROPERLY) {
      history.push({
        pathname: AppPaths.Subscription.TellUsMore(device.moduleId),
        state: {
          selectedCancelReason,
          subscriptionCategory,
        },
        search: window.location.search,
      });
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.COLLAR_IS_PHYSICALLY_BROKEN) {
      if (provideReplaceOptionForModuleVersions.includes(collarModel)) {
        history.push({
          pathname: AppPaths.Subscription.Replace(device.moduleId),
          state: {
            selectedCancelReason,
            subscriptionCategory,
          },
          search: window.location.search,
        });
      } else if (showUpgradeForModuleVersions.includes(collarModel)) {
        history.push({
          pathname: AppPaths.Subscription.Upgrade(device.moduleId),
          state: {
            selectedCancelReason,
            subscriptionCategory,
          },
          search: window.location.search,
        });
      }
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE) {
      history.push({
        pathname: AppPaths.Subscription.TooExpensive(device.moduleId),
        search: window.location.search,
        state: { subscriptionCategory },
      });
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY) {
      history.push({
        pathname: AppPaths.Subscription.DogDoesNotRunAway(device.moduleId),
        search: window.location.search,
        state: { subscriptionCategory },
      });
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.BILLING_ERROR) {
      history.push({
        pathname: AppPaths.Subscription.ContactCx(device.moduleId),
        search: window.location.search,
        state: { selectedCancelReason, subscriptionCategory },
      });
    } else if (selectedCancelReason === gqlTypes.zendeskCancellationReason.DOG_WILL_NOT_WEAR_COLLAR) {
      history.push({
        pathname: AppPaths.Subscription.MeetMakers(device.moduleId),
        state: {
          selectedCancelReason,
          subscriptionCategory,
        },
        search: window.location.search,
      });
    } else {
      throw new Error('Something went wrong.');
    }
  };

  const handleCancel = () => {
    if (!selectedCancelReason || !device.subscription?.id) {
      throw new Error('Something went wrong.');
    }

    events.cancellationReasonSelectedAndClicked({
      petId: device.pet?.id,
      cancellationReason: selectedCancelReason,
      subscriptionCategory,
    });

    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.backFromReasonPage({ petId: device.pet?.id, subscriptionCategory })}
          closeButtonAnalyticsEvent={() => events.closeFromReasonPage({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>What's up with {shortPetName}'s collar?</h1>
          <p>
            We don't want you and {petName} to leave, but your feedback can still help us improve — and help us keep
            more dogs safe and healthy.
          </p>

          <div className={styles.reasonHeader}>Reason for cancellation:</div>
          <div className={styles.content}>
            <Chooser
              onSelect={(selectedReason) => {
                setSelectedCancelReason(selectedReason);
              }}
              selectedOption={selectedCancelReason}
              groups={[
                {
                  options: [
                    {
                      content: "The collar doesn't work right",
                      value: gqlTypes.zendeskCancellationReason.COLLAR_DOES_NOT_WORK_PROPERLY,
                    },
                    {
                      content: 'The collar is physically broken',
                      value: gqlTypes.zendeskCancellationReason.COLLAR_IS_PHYSICALLY_BROKEN,
                    },
                    {
                      content: "My dog doesn't like wearing the collar",
                      value: gqlTypes.zendeskCancellationReason.DOG_WILL_NOT_WEAR_COLLAR,
                    },
                    {
                      content: "My dog doesn't run away",
                      value: gqlTypes.zendeskCancellationReason.DOG_DOES_NOT_RUN_AWAY,
                    },
                    {
                      content: 'The membership is too expensive',
                      value: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
                    },
                    {
                      content: 'There was a billing error',
                      value: gqlTypes.zendeskCancellationReason.BILLING_ERROR,
                    },
                    { content: 'Other', value: gqlTypes.zendeskCancellationReason.OTHER },
                  ],
                },
              ]}
              showRadio
              compact
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {selectedCancelReason && navigationReasons.includes(selectedCancelReason) ? (
            <Button className={styles.button} disabled={selectedCancelReason === undefined} onClick={handleNavigate}>
              Continue
            </Button>
          ) : (
            <CancelConfirmationModal
              onContinue={() => handleCancel()}
              pageName={'Manage Membership'}
              petId={device.pet!.id}
              trigger={
                <Button className={styles.button} disabled={selectedCancelReason === undefined}>
                  Continue
                </Button>
              }
            />
          )}
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
