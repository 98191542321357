import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import CheckoutValueProps from '../../../components/CheckoutValueProps';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import SubscriptionOptions from '../../../components/SubscriptionOptions';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import * as events from '../../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import * as types from '../../../types';
import { series2CollarId } from '../../../types';
import AddToBagButton from '../components/AddToBagButton';
import BandVariantOptions from '../components/BandVariantOptions';
import ProductTitle from '../components/ProductTitle';
import SoldOutBanner from '../components/SoldOutBanner';
import useIsSkuBlocked from '../hooks/useIsSkuBlocked';
import useSubscriptionSelector from '../hooks/useSubscriptionSelector';
import useVariantSelector from '../hooks/useVariantSelector';
import styles from '../ProductDetails.module.scss';
import ProductFeatures from './components/ProductFeatures';
import SubItems from './components/SubItems';

const VALUE_PROPS = ['Free shipping'];

interface OnChangeHandlerProps {
  priceInCents: number;
  productName: string;
}

function useOnChangeHandlers({ priceInCents, productName }: OnChangeHandlerProps) {
  const onColorChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series2CollarColorSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSizeChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series2CollarSizeSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSelectedVariantChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series2CollarVariantSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  return {
    onColorChange,
    onSizeChange,
    onSelectedVariantChange,
  };
}

function selectedVariantImageUrls(variant: types.IVariant): string[] {
  const lifestyleImageDirectory = `/lifestyle_images/smart-collar-v2/${variant.options.color}`;
  return [
    `/product_images/smart-collar-v2/${variant.options.color}.jpg`,
    `${lifestyleImageDirectory}/01.jpg`,
    `${lifestyleImageDirectory}/02.jpg`,
    `${lifestyleImageDirectory}/03.jpg`,
    `${lifestyleImageDirectory}/04.jpg`,
  ];
}

interface SubscriptionOptionsProps {
  onSubscriptionChange: (sku: string | undefined) => void;
  selectedSubscriptionSku: string | undefined;
  productName: string;
}

function Series2SubscriptionOptions({
  onSubscriptionChange,
  selectedSubscriptionSku,
  productName,
}: SubscriptionOptionsProps) {
  const subscriptionProducts = useSubscriptionProducts(series2CollarId);
  return (
    <SubscriptionOptions
      allowNoSubscription
      onSubscriptionChange={onSubscriptionChange}
      selectedSubscriptionSku={selectedSubscriptionSku}
      subscriptionProducts={subscriptionProducts}
      productName={productName}
      startAsUnselected={false}
      disableSection={false}
    />
  );
}

interface Series2CollarDetailsProps {
  priceInCents: number;
  productName: string;
  variants: types.IVariant[];
}

export default function Series2CollarDetails({ priceInCents, productName, variants }: Series2CollarDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    priceInCents,
    productName,
  });

  const { changeVariantOptions, selectedVariant } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants,
  });
  const isBlocked = useIsSkuBlocked(selectedVariant.sku);

  const subscriptionProducts = useSubscriptionProducts(series2CollarId);
  const { selectedSubscription, selectSubscriptionSku } = useSubscriptionSelector(subscriptionProducts);

  useEffect(() => {
    events.bandViewed();
  }, []);

  return (
    <>
      <div className={styles.productContainer}>
        <div className={styles.imageGalleryContainer}>
          <ImageGallery
            media={selectedVariantImageUrls(selectedVariant).map((url) => ({
              type: 'image',
              url,
              retinaWidth: 612,
              retinaHeight: 612,
            }))}
          />
        </div>
        <div className={styles.productDetails}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <CheckoutValueProps valueProps={VALUE_PROPS} />
            <p className={styles.description}>
              Chew-proof, water-proof, and generally dog-proof. Bluetooth and Wi-Fi enabled to track your walks and
              activity, and keep your dog in good shape. Charging accessories included.
            </p>
          </div>

          <BandVariantOptions
            bandSeries={types.BandSeries.F1}
            changeVariantOptions={changeVariantOptions}
            selectedVariant={selectedVariant}
            variants={variants}
            startSizeAsUnselected={false}
          />

          {isBlocked && <SoldOutBanner />}

          <Series2SubscriptionOptions
            onSubscriptionChange={selectSubscriptionSku}
            selectedSubscriptionSku={selectedSubscription?.sku}
            productName={productName}
          />

          <div className={styles.productAction}>
            <AddToBagButton
              disabled={isBlocked}
              onAddToBag={() => {
                if (isBlocked) {
                  return false;
                }

                events.series2CollarAddedToBag({
                  color: selectedVariant.options.color,
                  priceInCents,
                  productName,
                  size: selectedVariant.options.size,
                  sku: selectedVariant.sku,
                });

                if (selectedSubscription) {
                  events.series2SubscriptionAddedToBag({
                    priceInCents: selectedSubscription.priceInCents,
                    productId: selectedSubscription.id,
                    productName: selectedSubscription.name,
                    sku: selectedSubscription.sku,
                  });

                  dispatch(
                    storeCartActions.addBundleCartItem({
                      sku: selectedVariant.sku,
                      subscriptionSku: selectedSubscription.sku,
                    }),
                  );
                } else {
                  dispatch(storeCartActions.addSingleLineCartItem({ sku: selectedVariant.sku }));
                }

                history.push(AppPaths.Bag);

                return true;
              }}
            />
          </div>
        </div>
      </div>

      {selectedVariant && <SubItems variant={selectedVariant} />}
      <ProductFeatures />
    </>
  );
}
