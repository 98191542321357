import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateID } from '../lib/util';
import * as types from '../types';
import { baseCartReducers } from './baseCart';

interface IAddSeries3UpgradeCartItem {
  sku: string;
  subscriptionSku: string;
  moduleId: string;
}

export const cartInitialState: types.Series3UpgradeCart = {
  cartItems: {},
};

const name = 'series3UpgradeCart' as const;
export const { actions: cartActions, reducer: cartReducer } = createSlice({
  name,
  initialState: cartInitialState,
  reducers: {
    ...baseCartReducers,
    addCoupon<T extends types.Cart>(cart: T, { payload: couponCode }: PayloadAction<string>) {
      // Coupon and referral code cannot be stacked
      const { referralCode, ...cartWithoutReferralCode } = cart;

      return {
        ...cartWithoutReferralCode,
        couponCode,
      };
    },
    removeCoupon<T extends types.Cart>(cart: T) {
      return {
        ...cart,
        couponCode: undefined,
      };
    },
    addUpgradeCartItem(cart: types.Series3UpgradeCart, { payload }: PayloadAction<IAddSeries3UpgradeCartItem>) {
      // Make sure we don't create duplicate cart items for the same module id
      const existingCartItemId = Object.values(cart.cartItems).find(
        (cartItem) => cartItem.forModuleId === payload.moduleId,
      )?.cartItemId;

      const cartItem: types.ForModuleBundleCartItem = {
        cartItemId: existingCartItemId ?? generateID(),
        lineItem: {
          lineItemId: generateID(),
          sku: payload.sku,
        },
        subscriptionLineItem: {
          lineItemId: generateID(),
          sku: payload.subscriptionSku,
        },
        forModuleId: payload.moduleId,
        quantity: 1,
      };

      return {
        ...cart,
        cartItems: {
          ...cart.cartItems,
          [cartItem.cartItemId]: cartItem,
        },
      };
    },

    resetCart() {
      return { ...cartInitialState };
    },
  },
});
