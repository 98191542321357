import * as types from '../../types';
import { logInternalError } from '../errors';
import { webApiClient } from './apiUtils';

interface GetRemoteConfigOptions {
  upgradeFlow?: boolean;
  anonymousId?: string;
}

export async function getRemoteConfig({
  upgradeFlow,
  anonymousId,
}: GetRemoteConfigOptions = {}): Promise<types.RemoteConfig> {
  try {
    const response = await webApiClient.get<types.RemoteConfig>('/api/ecommerce/products', {
      params: {
        ...(upgradeFlow ? { upgradeFlow: 'true' } : {}),
        ...(anonymousId ? { anonymousId } : {}),
      },
    });

    const products = response.data.products;
    if (!products || products.length < 1) {
      throw new Error('No products found');
    }

    return response.data;
  } catch (error) {
    logInternalError(error);
    throw new Error('Unable to load products');
  }
}
