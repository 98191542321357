import Decimal from 'decimal.js';

export function centsToDollarsNumber(cents: number) {
  return new Decimal(Math.round(cents)).dividedBy(100).toNumber();
}

export function centsToDollars(cents: number): string {
  return centsToDollarsNumber(cents).toFixed(2);
}

export function dollarsToCents(dollars: number): number {
  return Math.round(new Decimal(dollars).times(100).toNumber());
}
