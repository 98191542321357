import analytics, { IdentifyTraits } from '.';
import { typedWindow } from '../Window';
import {
  ECOM_EVENT_CHECKOUT_STARTED,
  ECOM_EVENT_CHECKOUT_STEP_COMPLETED,
  ECOM_EVENT_ORDER_COMPLETED,
  ECOM_EVENT_PAYMENT_INFO_ENTERED,
  ECOM_EVENT_PRODUCT_ADDED,
  ECOM_EVENT_PRODUCT_VIEWED,
  SegmentProduct,
} from './events';

/**
 * For analytics, we currently use a forked version of Segment.io's analytics.js library. It already has an
 * integration with many analytics services, including Facebook's pixel, and we have configured it to send events
 * to our Facebook pixel.
 *
 * However, we currently want a second Facebook pixel for e-commerce events that contain S3 collar kits. For this
 * pixel, we don't events for cart/checkout actions that do not contain a collar kit. The existing Facebook pixel
 * will remain as-is, firing events for actions regardless of the product being purchased, and will live side-by-side
 * with this additional FB pixel. Since the Segment analytics.js library does not support multiple pixel ids, this
 * library will interact directly with the Facebook Pixel SDK for this alternate pixel.
 */
const FB_S3_COLLAR_PIXEL_ID = process.env.REACT_APP_FB_S3_COLLAR_PIXEL_ID || '';

// Map standard Segment e-commerce event names to a corresponding method that will fire Facebook Pixel events
type EcommerceEventTrack = (messageId: string, properties: any) => void;
const FB_ECOMMERCE_EVENT_MAP: Record<string, EcommerceEventTrack> = {
  [ECOM_EVENT_PRODUCT_VIEWED]: fbProductViewed,
  [ECOM_EVENT_PRODUCT_ADDED]: fbProductAdded,
  [ECOM_EVENT_CHECKOUT_STARTED]: fbCheckoutStarted,
  [ECOM_EVENT_CHECKOUT_STEP_COMPLETED]: fbCheckoutStepCompleted,
  [ECOM_EVENT_PAYMENT_INFO_ENTERED]: fbPaymentInfoEntered,
  [ECOM_EVENT_ORDER_COMPLETED]: fbOrderCompleted,
};

interface SegmentCartEventProperties {
  products?: SegmentProduct[];
  total?: number;
  value?: number;
}

interface FbEventProperties {
  content_ids?: string[];
  content_type?: string;
  content_name?: string;
  currency?: string;
  value?: string;
  contents?: { id: string; quantity: number; item_price: string }[];
  num_items?: number;
}

function isProductNonUpgradeSeries3CollarKit(product: SegmentProduct) {
  return product.id.startsWith('F3-MBCUA-') && !product.isUpgrade;
}

function propertiesContainsS3CollarKit(properties: any) {
  if (properties.products) {
    return properties.products.some((product: SegmentProduct) => isProductNonUpgradeSeries3CollarKit(product));
  }

  return isProductNonUpgradeSeries3CollarKit(properties);
}

function fbPixel(...args: any[]) {
  // In production, use the Facebook Pixel SDK. In development, do nothing.
  const fbq =
    (process.env.REACT_APP_ENVIRONMENT === 'production' && FB_S3_COLLAR_PIXEL_ID && typedWindow.fbq) ||
    (() => {
      // Development environment. Do nothing
    });

  fbq(...args);
}

// Ported from our forked analytics.js library
// https://github.com/barkinglabs/analytics.js/blob/f0a20a8d7396fb5a255e0088a0942adbd8a281ba/analytics.js#L5760-L5808
function formatTraits(traits: IdentifyTraits) {
  const email = traits.email?.toLowerCase();

  let firstName: string | undefined = undefined;
  let lastName: string | undefined = undefined;

  // Check for firstName property
  // else check for name
  if (traits.firstName) {
    firstName = traits.firstName;
    lastName = traits.lastName;
  } else {
    const nameArray = (traits.name && traits.name.toLowerCase().split(' ')) || [];
    firstName = nameArray.shift();
    lastName = nameArray.pop();
  }

  // Gender as m or f
  let gender: string | undefined = undefined;
  if (traits.gender && typeof traits.gender === 'string') {
    gender = traits.gender.slice(0, 1).toLowerCase();
  }

  // Format birthday as yyyymmdd
  const birthday = traits.birthday && traits.birthday.toISOString().slice(0, 10).split('-').join('');
  const address = traits.address || {};
  const city = address.city && address.city.split(' ').join('').toLowerCase();
  const state = address.state && address.state.toLowerCase();
  const postalCode = address.postalCode;

  const external_id = analytics?.userOrAnonymousId() || undefined;

  const fbTraits: Record<string, string | undefined> = {
    em: email,
    fn: firstName,
    ln: lastName,
    ph: traits.phone,
    ge: gender,
    db: birthday,
    ct: city,
    st: state,
    zp: postalCode,
    external_id,
  };

  // Remove all undefined values
  Object.keys(fbTraits).forEach((key) => fbTraits[key] === undefined && delete fbTraits[key]);

  return fbTraits;
}

function onTrack(event: string, properties: any, messageId: string) {
  const mappedEvent = FB_ECOMMERCE_EVENT_MAP[event];
  if (!mappedEvent || !propertiesContainsS3CollarKit(properties)) {
    return;
  }
  mappedEvent(messageId, properties);
}

function onIdentify(_userId: string, identifyTraits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
  const traits = formatTraits(identifyTraits ?? {});
  fbPixel('init', FB_S3_COLLAR_PIXEL_ID, traits);
}

export function fbInit() {
  const segmentTraits = analytics.userTraits();
  const existingTraits =
    Object.keys(segmentTraits).length > 0
      ? formatTraits(segmentTraits)
      : { external_id: analytics.userOrAnonymousId() };

  fbPixel('init', FB_S3_COLLAR_PIXEL_ID, existingTraits);

  // To fire the events to the alternate Facebook pixel, we'll use the callbacks from Segment's analytics.js
  // library. This way, we can get the exact same event, properties, and options that were used to generate
  // the message sent to our analytics backend and other analytics.js integrations.
  analytics.onTrack(onTrack);
  analytics.onIdentify(onIdentify);
}

/**
 * Map Segment e-commerce event properties to Facebook Pixel event properties
 */
function fbProductEventProperties(properties: SegmentProduct): FbEventProperties {
  return {
    content_ids: [properties.id],
    content_type: 'product',
    content_name: properties.name,
    currency: 'USD',
    value: Number(properties.price).toFixed(2),
    contents: [
      {
        id: properties.id,
        quantity: properties.quantity,
        item_price: Number(properties.price).toFixed(2),
      },
    ],
  };
}

/**
 * Map Segment e-commerce cart event properties (multiple products) to Facebook Pixel event properties.
 * We only include products that are S3 collar kits or S3 subscriptions.
 */
function fbCartEventProperties(properties: SegmentCartEventProperties): FbEventProperties {
  const fbEventProperties: FbEventProperties = {};

  let total = 0;
  if (properties.products) {
    const contentIds = [];
    const contents = [];

    const products: SegmentProduct[] = properties.products.filter((product) => {
      if (isProductNonUpgradeSeries3CollarKit(product)) {
        return true;
      }

      // We'll also include S3 subscriptions since they're bundled with S3 collar kits
      const isS3Subscription = product.id.startsWith('sub-monthly-');
      if (isS3Subscription) {
        return true;
      }

      return false;
    });
    for (const product of products) {
      contentIds.push(product.id);
      contents.push({
        id: product.id,
        quantity: product.quantity,
        item_price: Number(product.price).toFixed(2),
      });

      total += product.price * product.quantity;
    }

    fbEventProperties.content_ids = contentIds;
    fbEventProperties.contents = contents;
    fbEventProperties.num_items = products.length;
  }

  if (total) {
    fbEventProperties.value = Number(total).toFixed(2);
    fbEventProperties.currency = 'USD';
  }

  return fbEventProperties;
}

function fbProductViewed(messageId: string, properties: SegmentProduct) {
  fbPixel('trackSingle', FB_S3_COLLAR_PIXEL_ID, 'ViewContent', fbProductEventProperties(properties), {
    eventID: messageId,
  });
}

function fbProductAdded(messageId: string, properties: SegmentProduct) {
  fbPixel('trackSingle', FB_S3_COLLAR_PIXEL_ID, 'AddToCart', fbProductEventProperties(properties), {
    eventID: messageId,
  });
}

function fbCheckoutStarted(messageId: string, properties: SegmentCartEventProperties) {
  fbPixel('trackSingle', FB_S3_COLLAR_PIXEL_ID, 'InitiateCheckout', fbCartEventProperties(properties), {
    eventID: messageId,
  });
}

function fbCheckoutStepCompleted(messageId: string, properties: SegmentCartEventProperties) {
  fbPixel('trackSingle', FB_S3_COLLAR_PIXEL_ID, 'CompleteRegistration', fbCartEventProperties(properties), {
    eventID: messageId,
  });
}

function fbPaymentInfoEntered(messageId: string) {
  fbPixel(
    'trackSingle',
    FB_S3_COLLAR_PIXEL_ID,
    'AddPaymentInfo',
    {},
    {
      eventID: messageId,
    },
  );
}

function fbOrderCompleted(messageId: string, properties: SegmentCartEventProperties) {
  fbPixel('trackSingle', FB_S3_COLLAR_PIXEL_ID, 'Purchase', fbCartEventProperties(properties), {
    eventID: messageId,
  });
}
