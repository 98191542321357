import validator from 'validator';
import * as yup from 'yup';
import { MINIMUM_PASSWORD_LENGTH } from './authentication';
import { validateEmail } from './util';

const ZIPCODE_REGEX = '^\\d{5}(?:-\\d{4})?$';
const ADDRESS_LINE_MAX_LENGTH = 40;

const NAME_REGEX = /^\p{L}+[\p{L} ,.'-]*$/u;
const NAME_ERROR_HELPER = `must start with a letter and contain only letters, spaces, or the following characters: ,.'-`;

export const addressValidationSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required('Please enter your first name.')
      .matches(new RegExp(NAME_REGEX), `First name ${NAME_ERROR_HELPER}`),
    lastName: yup
      .string()
      .required('Please enter your last name.')
      .matches(new RegExp(NAME_REGEX), `Last name ${NAME_ERROR_HELPER}`),
  })
  .when('$requiresAccountDetails', {
    is: true,
    then: (schema) =>
      schema.shape({
        email: yup
          .string()
          .required('Please enter your email address.')
          // Use our own validateEmail method instead of yup built-in because we look for .con in the email
          .test('validateEmail', 'Please enter a valid email address.', (value) => validateEmail(value ?? '')),
        password: yup
          .string()
          .required('Please enter a password.')
          .min(MINIMUM_PASSWORD_LENGTH, `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters.`),
      }),
  })
  .when('$requiresShippingDetails', {
    is: true,
    then: (schema) =>
      schema.shape({
        line1: yup
          .string()
          .required('Please enter your address.')
          .max(ADDRESS_LINE_MAX_LENGTH, `Address line 1 can be at most ${ADDRESS_LINE_MAX_LENGTH} characters`),
        line2: yup
          .string()
          .optional()
          .max(ADDRESS_LINE_MAX_LENGTH, `Address line 2 can be at most ${ADDRESS_LINE_MAX_LENGTH} characters`),
        city: yup
          .string()
          .required('Please enter your city.')
          .max(ADDRESS_LINE_MAX_LENGTH, `City can be at most ${ADDRESS_LINE_MAX_LENGTH} characters`),
        state: yup.string().required('Please select a state.'),
        zip: yup
          .string()
          .required('Please enter your zip code.')
          .matches(new RegExp(ZIPCODE_REGEX), 'Please enter a valid zip code.'),
        phone: yup
          .string()
          .required('Please enter your phone number.')
          .test('isValidUSPhone', 'Please enter a valid phone number.', (value) =>
            value ? validator.isMobilePhone(value, 'en-US') : true,
          ),
        shippingCode: yup.string().required('Please select a shipping method.'),
      }),
  });
