import React from 'react';
import * as gqlTypes from '../../../types/gql-op-types';
import { useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types';
import { maybeTruncatePetName } from '../../ManageSubscription/Collar/util/helpers';
import { troubleshoot as events } from '../../../lib/analytics/events';
import { useQuery } from '@apollo/client';
import { billingAccountQuery } from '../../../graphql-operations';
import { logInternalError } from '../../../lib/errors';
import { typedWindow } from '../../../lib/Window';
import { mkError } from '../../../lib/util';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import Button from '../../../components/Button';
import { DIGITAL_SUPPORT_LINE_NAME, PHONE_SUPPORT_NUMBER } from '../../../lib/util/phoneSupport';

export interface ContactCxState {
  selectedTroubleshootingReason: gqlTypes.zendeskCancellationReason;
}

export default function ContactCx() {
  const location = useLocation<ContactCxState>();
  const { selectedTroubleshootingReason } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const session = useSelector((state: AppState) => state.session);
  const petName = device.pet?.name ? maybeTruncatePetName(device.pet.name) : 'your dog';

  events.pageLoad({ petId: device.pet?.id }, 'Contact CX');

  const { data: accountData } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    onError(err) {
      logInternalError(err);
    },
  });

  // TODO: replace this chat with Decagon?
  // https://app.asana.com/0/1207101507783044/1208333496745341/f
  typedWindow.adaEmbed?.reset({
    greeting: '6504880420176cf9b0200f76', // This greeting value is the answer ID associated with a chatbot flow set up by CX,
    metaFields: {
      customername: `${accountData?.currentUser.firstName ?? ''}${
        // eslint-disable-next-line no-useless-concat
        accountData?.currentUser.lastName ? ' ' + accountData.currentUser.lastName : ''
      }`,
      email: session?.email || '',
      'SubCancel-Reason': selectedTroubleshootingReason,
    },
  });

  const showChatButton = useSelector(
    (state: AppState) => state.config.siteConfig.showInAppCancellationChatButton ?? false,
  );

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const phoneIconUrl = '/phone_icon.png';
  const chatIconUrl = '/chat_icon.png';

  const getHeaderCopy = () => {
    return `Maybe we can help fix the issue with ${petName}’s collar.`;
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.contactCxBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.contactCxClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>{getHeaderCopy()}</h1>

          <div className={styles.content}>
            <p>Our Customer Success Agents are experts at fixing collar issues. Can we help resolve your problem?</p>

            <div className={styles.inlineButtons}>
              {showChatButton && (
                <Button
                  className={styles.button}
                  disabled={typedWindow.adaEmbed === undefined} // Should not happen in production.
                  onClick={() => {
                    events.chatWithCx({ petId: device.pet?.id, troubleshootingReason: selectedTroubleshootingReason });
                    typedWindow.adaEmbed?.toggle();
                  }}
                >
                  <img src={chatIconUrl} alt="Chat" className={styles.buttonIcon} />
                  Chat
                </Button>
              )}
              <Button
                className={styles.button}
                secondary={true}
                onClick={() => {
                  events.callCx({ petId: device.pet?.id, troubleshootingReason: selectedTroubleshootingReason });
                  const phoneSupportDeeplinkUrl = `fi://phone_support?digitalSupportLineName=${DIGITAL_SUPPORT_LINE_NAME}&phoneSupportNumber=${PHONE_SUPPORT_NUMBER}`;
                  window.open(phoneSupportDeeplinkUrl);
                }}
              >
                <img src={phoneIconUrl} alt="Call" className={styles.buttonIcon} />
                Call
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
